import SortAnimation from './SortAnimation';
import {
  firebaseConfig,
  auth,
  db,
  functions,
  storage,
  storageRef,
  FirebaseFieldValue,
  FirebaseTimestamp,
  firebaseAuth,
  moment,
} from '../config/main_config';
// import css from './secretsauce.css?raw';
import { billingCheck } from '../variableModules/billingCheck.module';
import { onboardingCheck } from '../variableModules/onboardingCheck.module';
import { loadVariableSidebar } from '../variableModules/variableSidebar.module';
import { getCompanyImg } from '../variableModules/downloadFromSDK.module';
import { signOut, forceSignOut } from '../variableModules/logout.module';
import { onLoadFunction } from '../variableModules/standardOnLoadFunction.module';
import $ from 'jquery';
import { welcomeScreenCheck } from '../variableModules/welcomeScreen.module';
import { deiterateColors } from '../variableModules/colors.module';
import { apiCall } from '../variableModules/apiCall.module';
window.apiCall = apiCall;
window.jQuery = window.$ = $;
import * as WidgetData from '../../../Widgets/widgets.json';
import html2pdf from 'html2pdf.js';
window.moment = moment;
window.VITE_cloudFunctionURL = import.meta.env.VITE_cloudFunctionURL;
let amountOfUsers = '';
const currentURL = window.location.protocol;
const menus = [];
const users = [];
const policies = [];
const assuranceTasks = [];
const risks = [];
const assets = [];
const ismsRoles = [];
const keyDocument = [];
const overdueTasks = [];
const outsourcedServices = [];
const treatmentPlans = [];
window.WidgetBar;
var customerPlan;
let tenancyName;
let uid;
let map;
let OverdueTaskTemplate = document.querySelector('.TemplateOverdueTask').cloneNode(true);
document.querySelector('.TemplateOverdueTask').remove();
let TaskAssignmentTemplate = document.querySelector('.TaskAssignmentTemplate').cloneNode(true);
document.querySelector('.TaskAssignmentTemplate').remove();
let RatedRiskTemplate = document.querySelector('.RatedRiskTemplate').cloneNode(true);
document.querySelector('.RatedRiskTemplate').remove();

window.onload = onLoadFunction(uid);

window.getTenancyInfo = function () {
  const companyRef = db.collection('customers').doc(window.companyID);
  companyRef.get().then((doc) => {
    if (doc.exists) {
      const tenancySetup = doc.data().tenancySetup.isSetup;
      const { setupStage } = doc.data().tenancySetup;
      const { stage1Checklist } = doc.data().tenancySetup;
      const { stage2Checklist } = doc.data().tenancySetup;
      customerPlan = doc.data()?.customerPlan;
      window.customerPlan = customerPlan;
      SortAnimation();
      if (customerPlan === undefined) {
        window.customerPlanFriendly = 'iso';
        let currentPageLocation = window.location.pathname;
        // getallData();
        loadVariableSidebar(db, auth, currentPageLocation, 'default');
        const totalStages = 9;
        const setupStageInt = parseInt(setupStage);
        tenancyName = doc.data().customerFriendlyName;
        document.getElementById('tenancyName').textContent = tenancyName;
        // document.getElementById('iso270001Dashboard').style.display = 'none';
        // document.getElementById('starterPlanDashboard').style.display = 'none';

        configureWidgetBar();
      } else {
        window.customerPlanFriendly = 'starter';
        let currentPageLocation = window.location.pathname;
        billingCheck();
        welcomeScreenCheck();
        loadVariableSidebar(db, auth, currentPageLocation, customerPlan);

        tenancyName = doc.data().customerFriendlyName;
        document.getElementById('tenancyName').textContent = tenancyName;
        // getallData();
        // document.getElementById('iso270001Dashboard').style.display = 'none';
        // document.getElementById('starterPlanDashboard').style.display = 'none';

        configureWidgetBar();
      }
    } else {
    }

    const apiUrl =
      import.meta.env.VITE_cloudFunctionURL + '/deiterateInternalAPI/getSupportingDocs';
    const method = 'POST';
    const data = {
      companyID: window.companyID,
    };

    // auth.currentUser.getIdToken(true).then(async function (idToken) {
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${idToken}`,
    //   };
    //   apiCall(apiUrl, method, data, headers)
    //     .then((result) => {
    //       // Handle the API response data here
    //       document.querySelector(
    //         '[data-widgetID="SupportingDocsRequiresAttentionWidget"] .key',
    //       ).textContent = Object.keys(result.requiresAttention).length;
    //     })
    //     .catch((error) => {
    //       console.error('Error:', error);
    //       // Handle any errors that occurred during the API call
    //     });
    // });
  });
};

const configureWidgetBar = async (selector = '#WidgetBar') => {
  // Wait for dom content to load
  setTimeout(async () => {
    let WidgetBarNode = document.querySelector(selector);
    window.WidgetBar = coreui.Sidebar.getInstance(WidgetBarNode);
    window.WidgetBar.hide();

    let defaultStarterDashboard = [
      'OverdueTasksWidgetMediumTall',
      'UpcomingTasksWidgetMediumTall',
      'DataFlowDiagram',
      'DataStorageMap',
      'CompletedTasksWidgetMediumTall',
      'OverdueTasksCounterWidget',
      'PrivacyRequestsWidget',
      'RescheduledTasksPercentageWidget',
      'OnTimePerformanceWidget',
      'Logged30DaysAfterPerformWidget',
      'TasksMovedAndNotPerformedWidget',
    ];
    let defaultISODashboard = [
      'ComplianceHealthWidget',
      'ISMSObjectivesListWidget',
      'AssuranceHealthWidget',
      'UpcomingTasksWidgetMediumTall',
      'OverdueTasksWidgetMediumTall',
      'RiskHeatMapWidget',
      'DataStorageMap',
      'SupplyChainRisksChartWidget',
      'DataFlowDiagram',
      'TaskAssignmentListWidget',
      'DaysUntilNextExternalAuditDateWidget',
      'TotalRisksCountWidget',
      'UsersWithIncompleteReadingCountWidget',
      'OverdueTasksCounterWidget',
      'OnTimePerformanceWidget',
      'TotalSupplierCountWidget',
      'OpenNonConformitiesCountWidget',
      'SupportingDocsRequiresAttentionWidget',
    ];
    let defaultDashboard;

    if (window.customerPlanFriendly === 'iso') {
      defaultDashboard = defaultISODashboard;
    } else if (window.customerPlanFriendly === 'starter') {
      defaultDashboard = defaultStarterDashboard;
    } else {
      defaultDashboard = defaultISODashboard;
    }

    let dashboardLayout = [];

    if (window.userData?.mspImpersonation === true) {
      if (window.userData.hasOwnProperty('dashboardLayout')) {
        // Check if the array is only strings or objects
        for (const item of window.userData.dashboardLayout) {
          if (typeof item === 'object') {
            if (
              item.hasOwnProperty('tenancyID') &&
              item.hasOwnProperty('dashboardLayout') &&
              item.tenancyID === window.companyID
            ) {
              console.log(item.dashboardLayout);
              dashboardLayout = item.dashboardLayout;
              continue;
            }
          }
        }
        if (dashboardLayout.length <= 0) {
          dashboardLayout = defaultDashboard;
          let currentUserDash = window.userData.dashboardLayout;
          currentUserDash.push({
            tenancyID: window.companyID,
            dashboardLayout: dashboardLayout,
          });
          console.log(currentUserDash);
          await db.collection('users').doc(auth.currentUser.uid).set(
            {
              dashboardLayout: currentUserDash,
            },
            { merge: true },
          );
        }
      }
    } else {
      if (window.userData.hasOwnProperty('dashboardLayout')) {
        // Check if the array is only strings or objects
        for (const item of window.userData.dashboardLayout) {
          if (typeof item === 'string') {
            dashboardLayout.push(item);
            continue;
          }

          if (typeof item === 'object') {
            if (
              item.hasOwnProperty('tenancyID') &&
              item.hasOwnProperty('dashboardLayout') &&
              item.tenancyID === window.companyID
            ) {
              dashboardLayout = item.dashboardLayout;
              continue;
            }
          }
        }
      }
    }

    const widgetIDsToLoad = dashboardLayout.length > 0 ? dashboardLayout : defaultDashboard;
    window.dashboardLayout = widgetIDsToLoad;
    // for (let i = 0; WidgetData.widgets.length > i; i++) {
    //
    //   document.querySelector('#WidgetBar').append(WidgetData.widgets[i].widget.metadata.name);
    // }
    window.populateWidgetBar();
    loadWidgets(widgetIDsToLoad);
  }, 1000);
};

window.populateWidgetBar = () => {
  let template = `<p>
                <button class="btn btn-primary AddWidgetBtn"><span class="widgetName">Widget Name</span>  <span class="addIcon"><i class="fa-duotone fa-plus-circle color-white Font-Size--22"></i></span></button>
              </p>`;

  let categories = window.sortWidgets();

  // Iterate over each category
  for (const category in categories) {
    const categoryWidgets = categories[category].widgets;
    const categoryCount = categories[category].count;
    const categoryName = categories[category].category;

    // look at #widgetsAccordion and read the textContent of each .accordion-button to see if the textContent matches the category name
    // if it matches then note the data-coreui-target attribute of the widget element
    const categoryElement = document.querySelector(
      `[data-coreui-target="#${categoryName.replaceAll(/\s/g, '')}Widgets"]`,
    );

    // If the category element doesn't exist, create it
    if (!categoryElement) {
      const categoryElement = document.createElement('div');
      categoryElement.classList.add('category');
      categoryElement.setAttribute('data-coreui-target', categoryName);
      categoryElement.innerHTML = `<div class="category-header"><span class="category-name">${categoryName}</span><span class="category-count">${categoryCount}</span></div><div class="category-widgets"></div>`;

      // Append the category element to #widgetsAccordion
      document.querySelector('#widgetsAccordion').append(categoryElement);
    }

    document.querySelector(`#${categoryName.replaceAll(/\s/g, '')}Widgets`).innerHTML =
      '<div class="accordion-body"></div>';

    // Iterate over each widget in the category
    categoryWidgets.forEach((widget) => {
      // Create the widget element
      const widgetElement = document.createElement('div');
      widgetElement.classList.add('widget');
      widgetElement.setAttribute('data-widget', JSON.stringify(widget));
      widgetElement.innerHTML = template;
      widgetElement.querySelector('.widgetName').textContent = widget.metadata.name;
      widgetElement.addEventListener('click', () => {
        window.addWidget(widget.metadata.widgetID);
      });

      // Append the widget element to the category element
      document
        .querySelector(`#${categoryName.replaceAll(/\s/g, '')}Widgets .accordion-body`)
        .append(widgetElement);
    });
  }
};

window.sortWidgets = () => {
  const categories = {};

  // Iterate over each widget
  WidgetData.widgets.forEach((item) => {
    const category = item.widget.metadata.category;

    // If the category doesn't exist in the categories object, create it
    if (!categories[category]) {
      categories[category] = {
        category: category,
        widgets: [],
        count: 0,
      };
    }

    // Add the widget to the corresponding category
    categories[category].widgets.push(item.widget);
    categories[category].count++;
  });

  // Convert the categories object to an array
  return categories;
};

window.toggleEditMode = () => {
  let editButton = `<button class="edit-button" onmousedown="window.deleteWidget(this);"><i class="fa-duotone fa-xmark color-white"></i></button>`;
  document.querySelectorAll('span[class*="Block--"]').forEach((block) => {
    // Append edit button to each block or remove if already exists
    if (!block.querySelector('.edit-button')) {
      // block.classList.toggle('jiggle');
      block.classList.toggle('Block--isDraggable');
      block.insertAdjacentHTML('beforeend', editButton);
    } else {
      block.classList.toggle('Block--isDraggable');
      // block.classList.toggle('jiggle');
      block.querySelector('.edit-button').remove();
    }
  });
};

document.getElementById('widgetContainer').addEventListener('click', function (event) {
  // Check if the clicked element is the parent, not one of its children
  if (event.target === this) {
    // document.querySelectorAll('.Block--isDraggable').forEach((block) => {
    //   // Append edit button to each block or remove if already exists
    //   if (block.querySelector('.edit-button')) {
    //     // block.classList.remove('jiggle');
    //     block.querySelector('.edit-button').remove();
    //   }
    // });
    if (!document.querySelector('#WidgetBar').classList.contains('hide')) {
      window.WidgetBar.toggle();
      window.toggleEditMode();
      window.saveDashboardLayout();
    }
  }
});

window.deleteWidget = (element) => {
  let block = element.closest('.Block--isDraggable');
  let widgetID = block.getAttribute('data-widgetID');

  block.remove();
  window.saveDashboardLayout();
  return;
};

window.addWidget = async (widgetID) => {
  return loadWidgets([widgetID], true);
};

// const loadWidgets = async () => {
//   // Load Data Storage Map
//   window.getSupplierData(false);
//   // Load Data Flow Diagram
//   window.getSupplierData(true);

//   /* Assurance Health Widget Start */

//   let assuranceHealth = await getAssuranceHealth();

//   DonutChart('DonutChart', {
//     chart: {
//       height: 225,
//       width: 335,
//       type: 'variablepie',
//     },
//     text: `${assuranceHealth.onTimePercentage >= 100 ? 100 : assuranceHealth.onTimePercentage}%</br> on-time`,
//     tooltip: {
//       valueSuffix: '%',
//     },
//     series: [
//       {
//         minPointSize: 10,
//         innerSize: '80%',
//         zMin: 0,
//         dataLabels: {
//           enabled: false,
//         },
//         name: 'Assurance Health',
//         data: [
//           {
//             name: 'On Time',
//             z: 60,
//             y: assuranceHealth.onTimePercentage >= 100 ? 100 : assuranceHealth.onTimePercentage,
//             color: deiterateColors[2],
//           },
//           {
//             name: 'Overdue',
//             z: 60,
//             y: assuranceHealth.overduePercentage >= 100 ? 100 : assuranceHealth.overduePercentage,
//             color: deiterateColors[1],
//           },
//         ],
//       },
//     ],
//   });
//   /* Assurance Health Widget End */

//   /* Overdue Tasks Widget Start */
// let assuranceHealth = await getAssuranceHealth();
//   document.querySelector('.logged30DaysAfterPerform').textContent = assuranceHealth.overdueCount;
// let assuranceHealth = await getAssuranceHealth();
// document.querySelectorAll('.OverdueTasksList').forEach((element) => {
//   let overdueTasks = assuranceHealth.toBeCompletedOverdue;
//   for (let i = 0; i < overdueTasks.length; i++) {
//     let task = overdueTasks[i];

//
//     if (task.hasOwnProperty('checkFrequency') && task.hasOwnProperty('checkDetail')) {
//       let baseTaskElement = OverdueTaskTemplate.cloneNode(true);
//
//
//       console.log(task.checkFrequency.nextCheck.toDate());
//       baseTaskElement.querySelector('.dueDate').textContent = new Date(
//         task.checkFrequency.nextCheck.toDate(),
//       ).toLocaleDateString();

//       baseTaskElement.querySelector('.TaskDescription').textContent = task.checkDetail.check;

//       baseTaskElement.addEventListener('mousedown', () => {
//         window.location.href = 'assurance-actions.html?assID=' + task.assID;
//       });
//       element.appendChild(baseTaskElement);
//     }
//   }
// });

//   /* Overdue Tasks Widget End */

//   /* Upcoming Tasks Widget Start */
//  let assuranceHealth = await window.getAssuranceHealth();
//   document.querySelectorAll('.UpcomingTasksList').forEach((element) => {
//     let overdueTasks = assuranceHealth.upcoming;
//     for (let i = 0; i < overdueTasks.length; i++) {
//       let task = overdueTasks[i];
//
//       // baseTaskElement.querySelector('.dueDate').textContent = task.dueDate;
//       if (task.hasOwnProperty('checkFrequency') && task.hasOwnProperty('checkDetail')) {
//         let baseTaskElement = OverdueTaskTemplate.cloneNode(true);
//
//
//         console.log(task.checkFrequency.nextCheck.toDate());
//         baseTaskElement.querySelector('.dueDate').textContent = new Date(
//           task.checkFrequency.nextCheck.toDate(),
//         ).toLocaleDateString();

//         baseTaskElement.querySelector('.TaskDescription').textContent = task.checkDetail.check;

//         baseTaskElement.addEventListener('mousedown', () => {
//           window.location.href = 'assurance-actions.html?assID=' + task.assID;
//         });
//         element.appendChild(baseTaskElement);
//       }
//     }
//   });

//   /* Upcoming Tasks Widget End */

//   /* Completed Tasks Widget Start */
//  let assuranceHealth = await window.getAssuranceHealth();
//   document.querySelectorAll('.CompletedTasksList').forEach((element) => {
//     let overdueTasks = assuranceHealth.completed;
//     for (let i = 0; i < overdueTasks.length; i++) {
//       let task = overdueTasks[i];
//

//       if (task.hasOwnProperty('checkFrequency') && task.hasOwnProperty('checkDetail')) {
//         let baseTaskElement = OverdueTaskTemplate.cloneNode(true);
//
//
//         console.log(task.checkFrequency.nextCheck.toDate());
//         baseTaskElement.querySelector('.dueDate').textContent = new Date(
//           task.checkFrequency.nextCheck.toDate(),
//         ).toLocaleDateString();

//         baseTaskElement.querySelector('.TaskDescription').textContent = task.checkDetail.check;

//         baseTaskElement.addEventListener('mousedown', () => {
//           window.location.href = 'assurance-actions.html?assID=' + task.assID;
//         });
//         element.appendChild(baseTaskElement);
//       }
//     }
//   });

//   /* Completed Tasks Widget End */

// /* Task Assignment Widget Start */
// let TaskAssignmentData = await window.getTaskAssignments();
//
// document.querySelectorAll('.TaskAssignmentList').forEach(async (element) => {
//   let count = 0;
//   Object.keys(TaskAssignmentData).forEach(async (uid) => {
//     count++;
//     let user = TaskAssignmentData[uid];
//
//     let baseTaskElement = TaskAssignmentTemplate.cloneNode(true);
//
//     console.log('Task Assignment: Name (user.name): ', user.name);
//     // baseTaskElement.querySelector('.dueDate').textContent = task.dueDate;
//     baseTaskElement.querySelector('.taskCount').textContent = user.count + ' Tasks';
//     baseTaskElement.querySelector('.taskPercentage').textContent =
//       user.percentage.toFixed(2) + '% of Tasks';
//
//     element.appendChild(baseTaskElement);
//     baseTaskElement.querySelector('.name').textContent = await window.nameFromUID(uid);
//     if (
//       count === Object.keys(TaskAssignmentData).length &&
//       element.querySelector('.noMoreUsers') == undefined
//     ) {
//       // Add text box underneath baseTaskElement that says no more users are assigned tasks
//       let div = document.createElement('div');
//       div.classList.add('noMoreUsers');
//       let b = document.createElement('b');
//       b.textContent = 'No more users are assigned tasks';
//       div.appendChild(b);
//       element.appendChild(div);
//     }
//   });
// });

// /* Task Assignment Widget End */

// /* User Access Audit */
// getUserAccessAuditDetails();

//   /* Internal Audit */
//   getInternalAuditData();

// /* Overdue Tasks Widget Start */
// let assuranceTaskPercentages = await window.getAssuranceTaskPercentages();
// document.querySelector('.OverdueTaskCounter .earlyTasks .count').textContent =
//   `${assuranceTaskPercentages.earlyPercentage}%`;
// document.querySelector('.OverdueTaskCounter .lateTasks .count').textContent =
//   `${assuranceTaskPercentages.latePercentage}%`;
// document.querySelector('.OverdueTaskCounter .veryLateTasks .count').textContent =
//   `${assuranceTaskPercentages.veryLatePercentage}%`;
// /* Overdue Tasks Widget End */
//   /* 70% of Tasks Completed Early Widget Start */
// let assuranceTaskPercentages = await window.getAssuranceTaskPercentages();
//   if (parseFloat(assuranceTaskPercentages.earlyPercentage) >= 70) {
//     document.querySelector('.SeventyPercentTasksCompletedEarly .value i').classList.add('fa-check');
//     document.querySelector('.SeventyPercentTasksCompletedEarly .value i').classList.add('color-green');
//     document
//       .querySelector('.SeventyPercentTasksCompletedEarly .value i')
//       .classList.remove('fa-triangle-exclamation');
//     document
//       .querySelector('.SeventyPercentTasksCompletedEarly .value i')
//       .classList.remove('color-orange');
//   } else {
//     document
//       .querySelector('.SeventyPercentTasksCompletedEarly .value i')
//       .classList.remove('fa-check');
//     document
//       .querySelector('.SeventyPercentTasksCompletedEarly .value i')
//       .classList.remove('color-green');
//     document.querySelector('.SeventyPercentTasksCompletedEarly .value i').classList.add('fa-xmark');
//     document
//       .querySelector('.SeventyPercentTasksCompletedEarly .value i')
//       .classList.add('color-red');
//   }
//   /* 70% of Tasks Completed Early Widget End */
//   /* 30% of Tasks Completed Late Widget Start */
// let assuranceTaskPercentages = await window.getAssuranceTaskPercentages();
//    if (parseFloat(assuranceTaskPercentages.overduePercentage) >= 30) {
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.add('fa-triangle-exclamation');
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.add('color-orange');
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.remove('fa-check');
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.remove('color-green');
//    } else {
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.remove('fa-triangle-exclamation');
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.remove('color-orange');
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.add('fa-check');
//      document
//        .querySelector('.ThirtyPercentOfTasksCompletedLate .value i')
//        .classList.add('color-green');
//    }
//   /* 30% of Tasks Completed Late Widget End */
// /* Rescheduled Tasks Count Widget Start */
// let rescheduledTasks = await window.getAssuranceTasksWithNextCheckChanged();
// document.querySelector('.RescheduledTasksPercentage .count').textContent = rescheduledTasks.tasksWithNextCheckChangedPercentage.toFixed(2) + '%';
//   let nonPerformedAndChanged = rescheduledTasks.assuranceTasksWithNextCheckChanged;
//
//   document.querySelector('.tasksMovedAndNotPerformed').textContent = nonPerformedAndChanged.length;
//   /* Rescheduled Tasks Count Widget End */

//   /* Heatmap Widget Start */
//   window.populateHeatmap();
//   /* Heatmap Widget End */

// let risks = await window.getRisks();
// let lowRatedRisks = risks.low;
//   let mediumRatedRisks = risks.medium;
//   let highRatedRisks = risks.high;
//   let extremeRatedRisks = risks.extreme;

//   /* Populate Smaller Rating Widgets Start */
//   document.querySelector('.totalRisksCount').textContent = risks.totalCount;
//   document.querySelector('.lowRatedRisksCount').textContent = lowRatedRisks.count;
//   document.querySelector('.mediumRatedRisksCount').textContent = mediumRatedRisks.count;
//   document.querySelector('.highRatedRisksCount').textContent = highRatedRisks.count;
//   document.querySelector('.extremeRatedRisksCount').textContent = extremeRatedRisks.count;

// /* Low Rated Risks Widget Start */
// document.querySelector('.LowRatedRisks .count').textContent = lowRatedRisks.count;
// for (let i = 0; i < lowRatedRisks.risks.length; i++) {
//   let risk = lowRatedRisks.risks[i];
//   let row = document.createElement('tr');
//   let idCell = document.createElement('td');
//   let riskCell = document.createElement('td');
//   let reviewDateCell = document.createElement('td');

//   idCell.textContent = risk.riskID.toString().padStart(2, '0');
//   riskCell.textContent = risk.risk;
//   reviewDateCell.textContent = new Date(risk.nextReview.toDate()).toLocaleDateString();

//   row.appendChild(idCell);
//   row.appendChild(riskCell);
//   row.appendChild(reviewDateCell);
//   document.querySelector('.LowRatedRisks .table tbody').appendChild(row);

//   let baseRiskElement = RatedRiskTemplate.cloneNode(true);
//   baseRiskElement.querySelector('.date').textContent = new Date(
//     risk.nextReview.toDate(),
//   ).toLocaleDateString();
//   baseRiskElement.querySelector('.TaskDescription').textContent = risk.risk;
//   document.querySelector('.RatedLowReview').appendChild(baseRiskElement);
// }
// /* Low Rated Risks Widget End */

// /* Medium Rated Risks Widget Start */
// document.querySelector('.MediumRatedRisks .count').textContent = mediumRatedRisks.count;
// for (let i = 0; i < mediumRatedRisks.risks.length; i++) {
//   let risk = mediumRatedRisks.risks[i];
//   let row = document.createElement('tr');
//   let idCell = document.createElement('td');
//   let riskCell = document.createElement('td');
//   let reviewDateCell = document.createElement('td');

//   idCell.textContent = risk.riskID.toString().padStart(2, '0');
//   riskCell.textContent = risk.risk;
//   reviewDateCell.textContent = new Date(risk.nextReview.toDate()).toLocaleDateString();

//   row.appendChild(idCell);
//   row.appendChild(riskCell);
//   row.appendChild(reviewDateCell);
//   document.querySelector('.MediumRatedRisks .table tbody').appendChild(row);

//   let baseRiskElement = RatedRiskTemplate.cloneNode(true);
//   baseRiskElement.querySelector('.date').textContent = new Date(
//     risk.nextReview.toDate(),
//   ).toLocaleDateString();
//   baseRiskElement.querySelector('.TaskDescription').textContent = risk.risk;
//   document.querySelector('.RatedMediumReview').appendChild(baseRiskElement);
// }
// /* Medium Rated Risks Widget End */

// /* High Rated Risks Widget Start */
// document.querySelector('.HighRatedRisks .count').textContent = highRatedRisks.count;
// for (let i = 0; i < highRatedRisks.risks.length; i++) {
//   let risk = highRatedRisks.risks[i];
//   let row = document.createElement('tr');
//   let idCell = document.createElement('td');
//   let riskCell = document.createElement('td');
//   let reviewDateCell = document.createElement('td');

//   idCell.textContent = risk.riskID.toString().padStart(2, '0');
//   riskCell.textContent = risk.risk;
//   reviewDateCell.textContent = new Date(risk.nextReview.toDate()).toLocaleDateString();

//   row.appendChild(idCell);
//   row.appendChild(riskCell);
//   row.appendChild(reviewDateCell);
//   document.querySelector('.HighRatedRisks .table tbody').appendChild(row);

//   let baseRiskElement = RatedRiskTemplate.cloneNode(true);
//   baseRiskElement.querySelector('.date').textContent = new Date(
//     risk.nextReview.toDate(),
//   ).toLocaleDateString();
//   baseRiskElement.querySelector('.TaskDescription').textContent = risk.risk;
//   document.querySelector('.RatedHighReview').appendChild(baseRiskElement);
// }
// /* High Rated Risks Widget End */

// /* Extreme Rated Risks Widget Start */
// document.querySelector('.ExtremeRatedRisks .count').textContent = extremeRatedRisks.count;
// for (let i = 0; i < extremeRatedRisks.risks.length; i++) {
//   let risk = extremeRatedRisks.risks[i];
//   let row = document.createElement('tr');
//   let idCell = document.createElement('td');
//   let riskCell = document.createElement('td');
//   let reviewDateCell = document.createElement('td');

//   idCell.textContent = risk.riskID.toString().padStart(2, '0');
//   riskCell.textContent = risk.risk;
//   reviewDateCell.textContent = new Date(risk.nextReview.toDate()).toLocaleDateString();

//   row.appendChild(idCell);
//   row.appendChild(riskCell);
//   row.appendChild(reviewDateCell);
//   document.querySelector('.ExtremeRatedRisks .table tbody').appendChild(row);

//   let baseRiskElement = RatedRiskTemplate.cloneNode(true);
//   baseRiskElement.querySelector('.date').textContent = new Date(
//     risk.nextReview.toDate(),
//   ).toLocaleDateString();
//   baseRiskElement.querySelector('.TaskDescription').textContent = risk.risk;
//   document.querySelector('.RatedExtremeReview').appendChild(baseRiskElement);
// }
// /* Extreme Rated Risks Widget End */

//   /* Start Supplier Widgets */
//   let suppliers = await window.getSuppliers();
//   let lowRatedSuppliers = suppliers.low;
//   let mediumRatedSuppliers = suppliers.medium;
//   let highRatedSuppliers = suppliers.high;
//   let extremeRatedSuppliers = suppliers.extreme;

//   /* Populate Smaller Rating Widgets Start */
// document.querySelector('.totalSuppliersCount').textContent = suppliers.count;
//   document.querySelector('.lowRatedSuppliersCount').textContent = lowRatedSuppliers.count;
//   document.querySelector('.mediumRatedSuppliersCount').textContent = mediumRatedSuppliers.count;
//   document.querySelector('.highRatedSuppliersCount').textContent = highRatedSuppliers.count;
//   document.querySelector('.extremeRatedSuppliersCount').textContent = extremeRatedSuppliers.count;
//   /* End Supplier Widgets */

// /* Supply-Chain Risks Widget Start */
// // Generate Series from Suppliers - only the ones with vendorManagementNeeded.toLowerCase() === 'yes'
// let supplyChainRisks = [];
// Object.keys(suppliers).forEach((rating) => {
//
//   if (rating === 'count') return;
//   let suppliersByRating = suppliers[rating];
//   suppliersByRating.suppliers.forEach((supplier) => {
//     if (
//       supplier.hasOwnProperty('vendorManagementNeeded') &&
//       supplier.vendorManagementNeeded.toLowerCase() === 'yes'
//     ) {
//       let tempData = {
//         name: supplier.assetDesc,
//         marker: {
//           symbol: 'circle',
//         },
//         data: Array(12).fill(0), // Initialize data array with 12 zeros
//       };
//
//       if (supplier.hasOwnProperty('upguardSecurityScoreHistory')) {
//         Object.keys(supplier.upguardSecurityScoreHistory).forEach((historyKey) => {
//           if (historyKey === 'idHistory') return;
//           let historyItem = supplier.upguardSecurityScoreHistory[historyKey];
//
//           let score = parseInt(historyItem.score.split('/')[0]);
//           let date = new Date(historyItem.checkDate.toDate());
//           let monthIndex = date.getMonth(); // Get the month index (0-11)
//           tempData.data[monthIndex] = { y: score, name: date.toLocaleDateString() }; // Set the score at the corresponding month index
//         });
//         supplyChainRisks.push(tempData);
//       }
//     } else {
//       return;
//     }
//   });
// });

//
// LineChart('SupplyChainRisksChart', {
//   series: supplyChainRisks,
// });

// /* Supply-Chain Risks Widget End */

// /* Non Conformities Widgets Start */
// let nonConformities = await window.getNonConformities();
//
// document.querySelector('.open-nonconformities').textContent = nonConformities.open.count;
//   document.querySelector('.closed-nonconformities').textContent = nonConformities.closed.count;

//   /* Non Conformities Widgets End */

// /* Start ISMS Objectives Widgets Start */
// let ismsObjectives = await window.getISMSObjectives();
// let DumbbellData = [];

// for (let i = 0; i < ismsObjectives.length; i++) {
//   let objective = ismsObjectives[i];

//   DumbbellData.push({
//     name: objective.objectiveText,
//     low: moment(objective.dateAdded.toDate(), 'DD/MM/YYYY').unix(),
//     high: moment(objective.objectiveTargetDate.toDate(), 'DD/MM/YYYY').unix(),
//   });
// }

// DumbBell('ISMSObjectiveTargetsChart', DumbbellData);

//   /* Start ISMS Objectives List */
// let ismsObjectives = await window.getISMSObjectives();
//   document.querySelector('.ISMSObjectivesList .count').textContent = ismsObjectives.length;
//   for (let i = 0; i < ismsObjectives.length; i++) {
//     let objective = ismsObjectives[i];
//     let row = document.createElement('tr');
//     let idCell = document.createElement('td');
//     let objectiveCell = document.createElement('td');
//     let targetDateCell = document.createElement('td');

//     idCell.textContent = objective.objectiveID.toString().padStart(2, '0');
//     objectiveCell.textContent = objective.objectiveText;
//     targetDateCell.textContent = new Date(
//       objective.objectiveTargetDate.toDate(),
//     ).toLocaleDateString();

//     row.appendChild(idCell);
//     row.appendChild(objectiveCell);
//     row.appendChild(targetDateCell);
//     document.querySelector('.ISMSObjectivesList .table tbody').appendChild(row);
//   }
//   /* End ISMS Objectives List */

//   /* Start Tasks being logged 30 days after performed */
// let logged30DaysAfterPerformedTasks = await window.getTasksLogged();
// document.querySelector('.logged30DayAfterPerformed').textContent =
//   logged30DaysAfterPerformedTasks.tasksAdded30DaysAfterLastCheckDate.length;
//   /* End Tasks being logged 30 days after performed */

// /* Start Next External Audit Date Widget */
// let nextExternalAuditDate = await window.getNextExternalAuditDate();
// document.querySelector('.ExternalAuditDateWidget .nextExternalAuditDate').textContent =
//   nextExternalAuditDate.date;

// // Check if next external audit date is in the past
// let isNextExternalAuditDateInPast = nextExternalAuditDate.days < 0;
// if (isNextExternalAuditDateInPast) {
//   document.querySelector('.ExternalAuditDateWidget .value i').classList.remove('fa-check');
//   document.querySelector('.ExternalAuditDateWidget .value i').classList.add('fa-triangle-exclamation');
//   document.querySelector('.ExternalAuditDateWidget .nextExternalAuditDate').remove();
//   document.querySelector('.ExternalAuditDateWidget .key').textContent =
//     'Your External Audit Date has past. Please update your company’s External Audit Date';
// } else {
//   document
//     .querySelector('.ExternalAuditDateWidget .value i')
//     .classList.remove('fa-triangle-exclamation');
//   document
//     .querySelector('.ExternalAuditDateWidget .value i')
//     .classList.add('fa-check');
// }
// /* End Next External Audit Date Widget */

// /* Start Days Until Next External Audit Date Widget */
// document.querySelector('.daysUntilNextExternalAuditDate').textContent =
//   nextExternalAuditDate.days;

// /* Start User Reading Progress List Widget */
// let companyUsers = await window.getCompanyUsers();
//

// let rows = [];

// for (let i = 0; i < companyUsers.users.length; i++) {
//   let user = companyUsers.users[i];
//   let row = document.createElement('tr');
//   let nameCell = document.createElement('td');
//   let progressCell = document.createElement('td');
//   progressCell.classList.add('progress-cell');

//   nameCell.textContent = user.name;
//   progressCell.textContent = user.readingProgress.totalReadProgress + '%';

//   row.appendChild(nameCell);
//   row.appendChild(progressCell);
//   rows.push(row);
// }
// rows.sort((a, b) => {
//   let progressA = parseFloat(a.querySelector('.progress-cell').textContent.split('%')[0]);
//   let progressB = parseFloat(b.querySelector('.progress-cell').textContent.split('%')[0]);
//   return progressB - progressA;
// });

// // Append the sorted rows to the table body
// let tableBody = document.querySelector('.UserReadingProgressList .table tbody');
// rows.forEach((row) => tableBody.appendChild(row));

// /* End User Reading Progress List Widget */

//   /* Start Employees Onboarded in the last 90 days widget */
// document.querySelector('.EmployeesOnboarded90DaysNotCompletedReading .value').textContent = companyUsers.usersOnboardedInTheLast90DaysAndNotCompletedReading;
//   document.querySelector('.EmployeesOnboarded90DaysCompletedReading .value').textContent = companyUsers.usersOnboardedInTheLast90DaysAndCompletedReading;

//   /* Start Users with Incomplete Reading Progress Count Widget */
// document.querySelector('.UsersWithIncompleteReadingCount').textContent = companyUsers.totalUsersWithout100Percent;
//   /* End Users with Incomplete Reading Progress Count Widget */

//   /* Start Users with Incomplete Reading Progress Percentage Widget */
//   document.querySelector('.UsersWithIncompleteReadingPercentage').textContent =
//     companyUsers.totalUsersWithout100PercentPercentage + '%';
//   /* End Users with Incomplete Reading Progress Percentage Widget */

// /* Start Changes Logged In The Last 12 Months Widget */
// let changesLoggedInTheLast12Months = await window.getChangesLoggedInTheLast12Months();
// if (changesLoggedInTheLast12Months['under6ChangesLoggedInTheLast12Months']) {
//   document
//     .querySelector('.ChangesLoggedInTheLast12Months .value i')
//     .classList.remove('fa-triangle-exclamation');
//   document.querySelector('.ChangesLoggedInTheLast12Months .value i').classList.add('fa-check');
//   document.querySelector('.ChangesLoggedInTheLast12Months .key').textContent =
//     'Under 6 Changes Logged In The Last 12 Months';
// } else if (changesLoggedInTheLast12Months['over6ChangesLoggedInTheLast12Months']) {
//   document.querySelector('.ChangesLoggedInTheLast12Months .value i').classList.remove('fa-check');
//   document
//     .querySelector('.ChangesLoggedInTheLast12Months .value i')
//     .classList.add('fa-triangle-exclamation');
//   document.querySelector('.ChangesLoggedInTheLast12Months .key').textContent =
//     'Over 6 Changes Logged In The Last 12 Months';
// }
// /* End Changes Logged In The Last 12 Months Widget */

// /* Start Incidents Logged In The Last 12 Months Widget */
// let incidentsLoggedInTheLast12Months = await window.getIncidentsLoggedInTheLast12Months();
// if (incidentsLoggedInTheLast12Months['under3IncidentsLoggedInTheLast12Months']) {
//   document
//     .querySelector('.IncidentsLoggedInTheLast12Months .value i')
//     .classList.remove('fa-triangle-exclamation');
//   document.querySelector('.IncidentsLoggedInTheLast12Months .value i').classList.add('fa-check');
//   document.querySelector('.IncidentsLoggedInTheLast12Months .key').textContent =
//     'Under 3 Incidents Logged In The Last 12 Months';
// } else if (incidentsLoggedInTheLast12Months['over3IncidentsLoggedInTheLast12Months']) {
//   document
//     .querySelector('.IncidentsLoggedInTheLast12Months .value i')
//     .classList.remove('fa-check');
//   document
//     .querySelector('.IncidentsLoggedInTheLast12Months .value i')
//     .classList.add('fa-triangle-exclamation');
//   document.querySelector('.IncidentsLoggedInTheLast12Months .key').textContent =
//     'Over 3 Incidents Logged In The Last 12 Months';
// }
// /* End Incidents Logged In The Last 12 Months Widget */

// /* Start Activity Rings Compliance Health Widget */
// let controlsImplemented = await window.getControlsImplemented();
// let assuranceHealth = await getAssuranceHealth();
//  let companyUsers = await window.getCompanyUsers();
//  ActivityRings('ActivityRingsChart', {
//    controlsImplementedPercentage: controlsImplemented.percentage,
//    tasksOnTimePercentage:
//      assuranceHealth.onTimePercentage >= 100 ? 100 : assuranceHealth.onTimePercentage,
//    usersCompletedReading: Math.ceil(
//      (companyUsers.totalUsersWith100Percent / companyUsers.totalUsers) * 100,
//    ),
//  });
// };

// Fake load time one
// const loadWidgets = async (widgetIDs, prepend = false) => {
//   // Show loading screen
//   const loadingScreen = document.getElementById('loadingScreen');
//   loadingScreen.style.display = 'flex';

//   // Get the container element
//   const widgetContainer = document.getElementById('widgetContainer');
//   if (!prepend) {
//     widgetContainer.innerHTML = '';
//   }

//   // Collect promises for eval executions
//   const evalPromises = [];

//   // Iterate over each widget in the JSON data
//   widgetIDs.forEach((widgetID) => {
//     // Find the widget data corresponding to the current widgetID
//     const widgetData = WidgetData.widgets.find(
//       (widget) => widget.widget.metadata.widgetID === widgetID,
//     );

//     if (widgetData) {
//       const widget = widgetData.widget;
//       if (prepend) {
//         widgetContainer.insertAdjacentHTML('afterbegin', widget.html);
//         let editButton = `<button class="edit-button" onmousedown="window.deleteWidget(this);"><i class="fa-duotone fa-xmark color-white"></i></button>`;
//         widgetContainer
//           .querySelector(`[data-widgetID="${widget.metadata.widgetID}"]`)
//           .insertAdjacentHTML('beforeend', editButton);
//       } else {
//         widgetContainer.insertAdjacentHTML('beforeend', widget.html);
//         widgetContainer
//           .querySelector(`[data-widgetID="${widget.metadata.widgetID}"]`)
//           .classList.toggle('Block--isDraggable');
//       }

//       // Wrap eval in a promise
//       const evalPromise = new Promise((resolve, reject) => {
//         try {
//           eval(widget.javascript);
//           resolve(); // Resolve when eval completes
//         } catch (error) {
//           reject(error); // Reject if there's an error in eval
//         }
//       });

//       evalPromises.push(evalPromise);
//     }
//   });

//   // Wait for all evals to complete
//   try {
//     await Promise.all(evalPromises);
//   } catch (error) {
//     console.error('Error executing eval:', error);
//   } finally {
//     // Hide loading screen
//     loadingScreen.style.display = 'none';
//   }
// };

const loadWidgets = async (widgetIDs, prepend = false) => {
  const loadingScreen = document.getElementById('loadingScreen');
  const spinner = document.getElementById('spinner');
  const stepText = document.getElementById('StepText');
  const loadingText = document.getElementById('loadingText');
  const loaderMessage = document.getElementById('loaderMessage');
  const refreshButton = document.getElementById('refreshButton');

  // Show loading screen
  loadingScreen.style.display = 'flex';

  // Set timeout to change loader after 10 seconds
  const timeout = setTimeout(() => {
    // Replace spinner with refresh icon
    spinner.style.display = 'none';
    loadingText.style.textAlign = 'center';
    loadingText.innerHTML = '<i class="fa-duotone fa-redo"></i><br /> Sorry for the Wait';
    stepText.innerText = 'This is taking a bit longer than usual.';
    loaderMessage.innerHTML = "Let's give it another shot – click 'Retry' below.";
    refreshButton.style.display = 'block'; // Show retry button
  }, 5000); //81000
  // Get the container element
  const widgetContainer = document.getElementById('widgetContainer');
  if (!prepend) {
    widgetContainer.innerHTML = '';
  }

  const evalPromises = [];

  widgetIDs.forEach((widgetID) => {
    const widgetData = WidgetData.widgets.find(
      (widget) => widget.widget.metadata.widgetID === widgetID,
    );

    if (widgetData) {
      const widget = widgetData.widget;
      if (prepend) {
        widgetContainer.insertAdjacentHTML('afterbegin', widget.html);
        let editButton = `<button class="edit-button" onmousedown="window.deleteWidget(this);"><i class="fa-duotone fa-xmark color-white"></i></button>`;
        widgetContainer
          .querySelector(`[data-widgetID="${widget.metadata.widgetID}"]`)
          .insertAdjacentHTML('beforeend', editButton);
      } else {
        widgetContainer.insertAdjacentHTML('beforeend', widget.html);
        widgetContainer
          .querySelector(`[data-widgetID="${widget.metadata.widgetID}"]`)
          .classList.toggle('Block--isDraggable');
      }

      // Wrap eval in a promise and check for async code
      const evalPromise = new Promise((resolve, reject) => {
        try {
          eval(widget.javascript);
          setTimeout(() => resolve(), 750); // Try have widgets rendered before loading screen hides
        } catch (error) {
          reject(error); // Reject if there's an error in eval
        }
      });

      evalPromises.push(evalPromise);
    }
  });

  try {
    await Promise.all(evalPromises);
  } catch (error) {
    console.error('Error executing eval:', error);
  } finally {
    clearTimeout(timeout); // Clear the timeout if everything loads
    loadingScreen.style.display = 'none'; // Hide loading screen
    document.querySelector('#SortAnimation').style.display = 'flex';
  }
};

// Actual load time one
// const loadWidgets = async (widgetIDs, prepend = false) => {
//   const loadingScreen = document.getElementById('loadingScreen');
//   const spinner = document.getElementById('spinner');
//   const stepText = document.getElementById('StepText');
//   const loadingText = document.getElementById('loadingText');
//   const loaderMessage = document.getElementById('loaderMessage');
//   const refreshButton = document.getElementById('refreshButton');

//   // Show loading screen
//   loadingScreen.style.display = 'flex';

//   // Set timeout to change loader after 10 seconds
//   const timeout = setTimeout(() => {
//     // Replace spinner with refresh icon
//     spinner.style.display = 'none';
//     loadingText.innerHTML = '<i class="fa-duotone fa-redo"></i>';
//     stepText.innerText = 'Please retry or wait a few more seconds.';
//     loaderMessage.innerHTML =
//       'Rendering your dashboard seems to be taking longer than expected. You can try refreshing the page.';
//     refreshButton.style.display = 'block'; // Show retry button
//   }, 81000); //81000

//   // Get the container element
//   const widgetContainer = document.getElementById('widgetContainer');
//   if (!prepend) {
//     widgetContainer.innerHTML = '';
//   }

//   const evalPromises = [];

//   widgetIDs.forEach((widgetID) => {
//     const widgetData = WidgetData.widgets.find(
//       (widget) => widget.widget.metadata.widgetID === widgetID,
//     );

//     if (widgetData) {
//       const widget = widgetData.widget;
//       if (prepend) {
//         widgetContainer.insertAdjacentHTML('afterbegin', widget.html);
//         let editButton = `<button class="edit-button" onmousedown="window.deleteWidget(this);"><i class="fa-duotone fa-xmark color-white"></i></button>`;
//         widgetContainer
//           .querySelector(`[data-widgetID="${widget.metadata.widgetID}"]`)
//           .insertAdjacentHTML('beforeend', editButton);
//       } else {
//         widgetContainer.insertAdjacentHTML('beforeend', widget.html);
//         widgetContainer
//           .querySelector(`[data-widgetID="${widget.metadata.widgetID}"]`)
//           .classList.toggle('Block--isDraggable');
//       }

//       // Wrap eval in a promise and check for async code
//       const evalPromise = new Promise(async (resolve, reject) => {
//         try {
//           // Execute eval and check if it returns a promise
//           const result = eval(widget.javascript);
//           if (result && typeof result.then === 'function') {
//             // If eval result is a promise, await it
//             await result;
//           }
//           resolve(); // Resolve when eval completes
//         } catch (error) {
//           reject(error); // Reject if there's an error in eval
//         }
//       });

//       evalPromises.push(evalPromise);
//     }
//   });

//   try {
//     await Promise.all(evalPromises);
//   } catch (error) {
//     console.error('Error executing eval:', error);
//   } finally {
//     clearTimeout(timeout); // Clear the timeout if everything loads
//     loadingScreen.style.display = 'none'; // Hide loading screen
//   }
// };

// Function to clear cache and reload the page
window.clearCacheAndReload = function () {
  if (window.caches) {
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  location.reload(true); // Force reload from server
};

window.saveDashboardLayout = async () => {
  // let dashboardLayout = [];
  let dashboardLayout = window.userData.dashboardLayout;
  let newDashboardLayout = [];
  if (dashboardLayout === undefined || dashboardLayout === null || dashboardLayout === '') {
    dashboardLayout = [];
  }
  // Check dashboardLayout for any objects and check if those objects have a tenancyID equal to current user's tenancyID

  let widgets = document.querySelectorAll('#widgetContainer span[class*="Block--"]');

  for (let index = 0; index < widgets.length; index++) {
    let widget = widgets[index];

    let widgetID;
    if (widget.dataset.hasOwnProperty('widgetid')) {
      widgetID = widget.dataset.widgetid;
    } else if (widget.dataset.hasOwnProperty('widgetId')) {
      widgetID = widget.dataset.widgetId;
    } else if (widget.dataset.hasOwnProperty('widgetID')) {
      widgetID = widget.dataset.widgetID;
    }

    newDashboardLayout.push(widgetID);
  }

  let itemUpdated = false;

  for (let i = 0; i < dashboardLayout.length; i++) {
    let item = dashboardLayout[i];

    if (typeof item === 'object') {
      if (item.hasOwnProperty('tenancyID') && item.tenancyID === window.companyID) {
        item.dashboardLayout = newDashboardLayout;
        itemUpdated = true;
      }
    }
  }

  // Add object to array if no existing item was updated
  if (!itemUpdated) {
    dashboardLayout.push({
      tenancyID: window.companyID,
      dashboardLayout: newDashboardLayout,
    });
  }

  console.log(dashboardLayout);

  db.collection('users')
    .doc(auth.currentUser.uid)
    .set(
      {
        dashboardLayout: dashboardLayout,
      },
      {
        merge: true,
      },
    )
    .then(() => {});
};

window.nameFromUID = async (uid) => {
  console.log(uid);
  return await db
    .collection('users')
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().firstName + ' ' + doc.data().lastName;
      } else {
        return 'User not found';
      }
    });
};

window.getControlsImplemented = async (companyID = window.companyID) => {
  let ismsSoaRef = db.doc(
    `customers/${companyID}/product/policyPackages/ISO27001keyDocuments/ismsSoa`,
  );
  let snapshot = await ismsSoaRef.get();

  let response = {
    count: 0,
    percentage: 0,
    getControlsImplemented: [],
  };

  if (snapshot.exists) {
    let ismsSoa = snapshot.data();

    if (Object.keys(ismsSoa).length <= 0) {
      // Pull from /compliance/iso270012022AnnexAControls
      let complianceRef = db.doc(`compliance/iso270012022AnnexAControls`);
      let complianceSnapshot = await complianceRef.get();

      if (complianceSnapshot.exists) {
        let controls = complianceSnapshot.data();

        Object.keys(controls).forEach((key) => {
          let control = controls[key];
          response.count++;
          response.getControlsImplemented.push(control);
        });

        response.percentage = Math.round((response.count / Object.keys(controls).length) * 100);
      }
    }

    let complianceRef = db.doc(`compliance/iso270012022AnnexAControls`);
    let complianceSnapshot = await complianceRef.get();

    if (complianceSnapshot.exists) {
      let controls = complianceSnapshot.data();
      Object.keys(controls).forEach((key) => {
        let control = ismsSoa[key] ?? controls[key];
        let controlImplemented =
          control?.controlApplicable === undefined ? true : control?.controlApplicable;
        if (controlImplemented) {
          response.count++;
          response.getControlsImplemented.push(control);
        }
      });

      response.percentage = Math.round((response.count / Object.keys(controls).length) * 100);
    }
  }

  return response;
};

window.getIncidentsLoggedInTheLast12Months = async (companyID = window.companyID) => {
  let assuranceTasksRef = db.doc(`customers/${companyID}/compliance/assurance`);
  let snapshot = await assuranceTasksRef.get();

  if (snapshot.exists) {
    let assuranceTasks = snapshot.data();

    let hasAss053 = assuranceTasks.hasOwnProperty('ass053');

    if (hasAss053) {
      let ass053 = assuranceTasks['ass053'];
      let hasCheckHistory = ass053.hasOwnProperty('checkHistory');
      if (hasCheckHistory) {
        let checkHistory = ass053['checkHistory'];
        let incidentsLoggedInTheLast12Months = {
          count: 0,
          changes: [],
          under3IncidentsLoggedInTheLast12Months: true,
          over3IncidentsLoggedInTheLast12Months: false,
        };

        Object.keys(checkHistory).forEach((checkHistoryKey) => {
          let historyItem = checkHistory[checkHistoryKey];
          let hasIncidentsLogged = historyItem.hasOwnProperty('amountOfIncidentsRecorded');
          if (hasIncidentsLogged) {
            let performedDate = new Date(historyItem.performedDate.toDate());
            if (
              performedDate.getFullYear() >= new Date().getFullYear() - 1 &&
              performedDate.getMonth() >= new Date().getMonth() - 12
            ) {
              incidentsLoggedInTheLast12Months['count'] += parseInt(
                historyItem.amountOfIncidentsRecorded,
              );
              incidentsLoggedInTheLast12Months['changes'].push(historyItem);
            }
          }
        });

        if (incidentsLoggedInTheLast12Months['count'] < 3) {
          incidentsLoggedInTheLast12Months['under3IncidentsLoggedInTheLast12Months'] = true;
          incidentsLoggedInTheLast12Months['over3IncidentsLoggedInTheLast12Months'] = false;
        } else if (incidentsLoggedInTheLast12Months['count'] >= 3) {
          incidentsLoggedInTheLast12Months['under3IncidentsLoggedInTheLast12Months'] = false;
          incidentsLoggedInTheLast12Months['over3IncidentsLoggedInTheLast12Months'] = true;
        }

        return incidentsLoggedInTheLast12Months;
      } else {
        return;
      }
    } else {
      return;
    }
  }
};

window.getChangesLoggedInTheLast12Months = async (companyID = window.companyID) => {
  let assuranceTasksRef = db.doc(`customers/${companyID}/compliance/assurance`);
  let snapshot = await assuranceTasksRef.get();

  if (snapshot.exists) {
    let assuranceTasks = snapshot.data();

    let hasAss054 = assuranceTasks.hasOwnProperty('ass054');

    if (hasAss054) {
      let ass054 = assuranceTasks['ass054'];
      let hasCheckHistory = ass054.hasOwnProperty('checkHistory');
      if (hasCheckHistory) {
        let checkHistory = ass054['checkHistory'];
        let changesLoggedInTheLast12Months = {
          count: 0,
          changes: [],
          under6ChangesLoggedInTheLast12Months: true,
          over6ChangesLoggedInTheLast12Months: false,
        };

        Object.keys(checkHistory).forEach((checkHistoryKey) => {
          let historyItem = checkHistory[checkHistoryKey];
          let hasChangesLogged = historyItem.hasOwnProperty('amountOfChangesRecorded');
          if (hasChangesLogged) {
            let performedDate = new Date(historyItem.performedDate.toDate());
            if (
              performedDate.getFullYear() >= new Date().getFullYear() - 1 &&
              performedDate.getMonth() >= new Date().getMonth() - 12
            ) {
              changesLoggedInTheLast12Months['count'] += parseInt(
                historyItem.amountOfChangesRecorded,
              );
              changesLoggedInTheLast12Months['changes'].push(historyItem);
            }
          }
        });

        if (changesLoggedInTheLast12Months['count'] < 6) {
          changesLoggedInTheLast12Months['under6ChangesLoggedInTheLast12Months'] = true;
          changesLoggedInTheLast12Months['over6ChangesLoggedInTheLast12Months'] = false;
        } else if (changesLoggedInTheLast12Months['count'] >= 6) {
          changesLoggedInTheLast12Months['under6ChangesLoggedInTheLast12Months'] = false;
          changesLoggedInTheLast12Months['over6ChangesLoggedInTheLast12Months'] = true;
        }

        return changesLoggedInTheLast12Months;
      } else {
        return;
      }
    } else {
      return;
    }
  }
};

window.getNextExternalAuditDate = async () => {
  let companySettingsRef = db
    .collection('customers')
    .doc(window.companyID)
    .collection('companySettings')
    .doc('complianceModules');
  let complianceModules = await companySettingsRef.get();
  if (complianceModules.exists) {
    let modules = complianceModules.data();

    if (modules.hasOwnProperty(`iso27001-2013`)) {
      let iso27001 = modules[`iso27001-2013`];
      // if (iso27001.hasOwnProperty('nextAuditDate')) {
      //   return iso27001['nextAuditDate'].toDate();
      // }

      for (let i = 0; i < Object.keys(iso27001).length; i++) {
        let setting = iso27001[Object.keys(iso27001)[i]];
        if (setting.settingName.toLowerCase() === 'next audit date') {
          // Calculate days until next audit date
          let nextAuditDate = new Date(setting.value.toDate());
          let today = new Date();
          let diff = nextAuditDate.getTime() - today.getTime();
          let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
          return {
            date: new Date(setting.value.toDate()).toLocaleDateString(),
            days: days,
          };
        }
      }
    }
  }
};

window.getReadingProgress = async (uid = auth.currentUser.uid) => {
  const userPoliciesRef = db.collection(`users/${uid}/policyReadProgress`);
  const usersAssignedPoliciesRef = db.collection(`users/${uid}/assignedPolicies`);
  const globalPoliciesRef = db.collection(
    `customers/${window.companyID}/product/policyPackages/policies`,
  );

  const userPoliciesSnapshot = await userPoliciesRef.get();

  let userReadProgress = [];
  let totalReadPolicies = 0;

  if (userPoliciesSnapshot.size > 0) {
    for (let i = 0; i < userPoliciesSnapshot.size; i++) {
      let policyDoc = userPoliciesSnapshot.docs[i];
      if (policyDoc.id === 'template') continue;
      let policy = policyDoc.data();
      let globalPolicy = await globalPoliciesRef.doc(policyDoc.id).get();
      let assignedPolicy = await usersAssignedPoliciesRef.doc(policyDoc.id).get();
      if (globalPolicy.exists) {
        globalPolicy = globalPolicy.data();
        let globalReadTime = globalPolicy.ReadTime;
        let totalScreens = globalPolicy.pagesCount;
        let userReadTime = policy.readProgress;
        let currentScreen = policy.currentScreen;
        let completed = assignedPolicy.completed;

        if (completed !== 'true') {
          userReadProgress.push({
            policyID: policyDoc.id,
            readProgress: 100,
          });
          totalReadPolicies++;
        } else {
          userReadProgress.push({
            policyID: policyDoc.id,
            readProgress: Math.round((userReadTime / Math.max(userReadTime, globalReadTime)) * 100),
          });
        }
      }
    }
  }

  const totalReadProgressPercentage =
    userPoliciesSnapshot.size > 0
      ? Math.round((totalReadPolicies / userPoliciesSnapshot.size) * 100)
      : 0;

  return {
    totalReadProgress: totalReadProgressPercentage,
    policies: userReadProgress,
  };
};
window.getCompanyUsers = async () => {
  const companyRef = db.doc(`customers/${window.companyID}`);
  const snapshot = await companyRef.get();

  const companyUsers = [];
  let usersOnboardedInTheLast90DaysAndNotCompletedReading = 0;
  let usersOnboardedInTheLast90DaysAndCompletedReading = 0;

  if (snapshot.exists) {
    let users = snapshot.data().customerOrg.users;

    for (let i = 0; i < Object.keys(users).length; i++) {
      let userKey = Object.keys(users)[i];
      let user = users[userKey];

      let userMap = {
        name: await window.nameFromUID(user.userID),
        uid: user.userID,
        onboardingDate: new Date(
          user.hasOwnProperty('dateAdded') && user.dateAdded !== null
            ? user.dateAdded.toDate()
            : new Date(),
        ).toLocaleDateString(),
        readingProgress: await window.getReadingProgress(user.userID),
        onboardedInTheLast90Days: false,
        totalReadProgress: 0,
      };

      userMap.totalReadProgress = userMap.readingProgress.totalReadProgress;

      let timeDiff = Math.abs(
        new Date().getTime() -
          new Date(
            user.hasOwnProperty('dateAdded') && user.dateAdded !== null
              ? user.dateAdded.toDate()
              : new Date(),
          ).getTime(),
      );
      let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (daysDiff <= 90) {
        userMap.onboardedInTheLast90Days = true;
        if (userMap.totalReadProgress < 100) {
          usersOnboardedInTheLast90DaysAndNotCompletedReading += 1;
        } else if (userMap.totalReadProgress >= 100) {
          usersOnboardedInTheLast90DaysAndCompletedReading += 1;
        }
      }

      companyUsers.push(userMap);
    }
  }

  // Count how many users don't have 100% readingProgress.totalReadProgress
  let totalUsers = companyUsers.length;
  let totalUsersWith100Percent = 0;
  companyUsers.forEach((user) => {
    if (user.readingProgress.totalReadProgress === 100) {
      totalUsersWith100Percent++;
    }
  });
  let totalUsersWithout100Percent = totalUsers - totalUsersWith100Percent;
  let totalUsersWithout100PercentPercentage = Math.round(
    (totalUsersWithout100Percent / totalUsers) * 100,
  );

  return {
    usersOnboardedInTheLast90DaysAndNotCompletedReading,
    usersOnboardedInTheLast90DaysAndCompletedReading,
    users: companyUsers,
    totalUsers: totalUsers,
    totalUsersWith100Percent: totalUsersWith100Percent,
    totalUsersWithout100Percent: totalUsersWithout100Percent,
    totalUsersWithout100PercentPercentage: totalUsersWithout100PercentPercentage,
  };

  return companyUsers;
};

window.getISMSObjectives = async () => {
  const ismsManualRef = db.doc(
    `customers/${window.companyID}/product/policyPackages/ISO27001keyDocuments/ismsManual`,
  );
  const snapshot = await ismsManualRef.get();

  const ismsObjectives = [];

  if (snapshot.exists) {
    let ismsObjectivesDocument = snapshot.data().ismsObjectives;

    Object.keys(ismsObjectivesDocument).forEach(async (objectiveID) => {
      let objective = ismsObjectivesDocument[objectiveID];

      ismsObjectives.push(objective);
    });
  }

  return ismsObjectives;
};

window.getNonConformities = async () => {
  const ismsRegisterRef = db.doc(`customers/${window.companyID}/compliance/ismsRegister`);
  const snapshot = await ismsRegisterRef.get();

  const nonConformities = {
    count: 0,
    open: {
      count: 0,
      percentage: 0,
      nonConformities: [],
    },
    closed: {
      count: 0,
      percentage: 0,
      nonConformities: [],
    },
  };

  const totalNonConformities = Object.keys(snapshot.data().register.nonConformities).length;

  if (snapshot.exists) {
    const nonConformitiesDocument = snapshot.data().register.nonConformities;

    Object.keys(nonConformitiesDocument).forEach(async (nonConformityID) => {
      let nonConformity = nonConformitiesDocument[nonConformityID];

      if (nonConformity.hasOwnProperty('nonConformityStatus')) {
        nonConformities.count += 1;

        switch (nonConformity.nonConformityStatus.toLowerCase()) {
          case 'open':
            nonConformities.open.count += 1;
            nonConformities.open.percentage =
              ((nonConformities.open.count / totalNonConformities) * 100).toFixed(2) + '%';
            nonConformities.open.nonConformities.push(nonConformity);
            break;
          case 'closed':
            nonConformities.closed.count += 1;
            nonConformities.closed.percentage =
              ((nonConformities.closed.count / totalNonConformities) * 100).toFixed(2) + '%';
            nonConformities.closed.nonConformities.push(nonConformity);
            break;
          default:
            break;
        }
      }
    });
  }

  return nonConformities;
};

window.getSuppliers = async () => {
  const suppliersRef = db.doc(`customers/${window.companyID}/compliance/asset`);
  const snapshot = await suppliersRef.get();

  const suppliers = {
    count: 0,
    low: {
      count: 0,
      percentage: 0,
      suppliers: [],
    },
    medium: {
      count: 0,
      percentage: 0,
      suppliers: [],
    },
    high: {
      count: 0,
      percentage: 0,
      suppliers: [],
    },
    extreme: {
      count: 0,
      percentage: 0,
      suppliers: [],
    },
  };

  const totalSuppliers = Object.keys(snapshot.data().register.assets).length;

  if (snapshot.exists) {
    const suppliersDocument = snapshot.data().register.assets;

    Object.keys(suppliersDocument).forEach(async (supplierID) => {
      let supplier = suppliersDocument[supplierID];

      if (!supplier.hasOwnProperty('assetRisk')) {
        supplier.assetRisk = 'low';
      }

      if (supplier.hasOwnProperty('assetIDStatus')) {
        let assetIDStatusMain = supplier.assetIDStatus.toLowerCase();
        if (supplier.hasOwnProperty('assetIDstatus')) {
          let assetIDStatusSub = supplier.assetIDstatus.toLowerCase();

          if (assetIDStatusSub !== 'active') {
            return;
          } else if (assetIDStatusMain !== 'active') {
            return;
          }
        }

        if (assetIDStatusMain !== 'active') {
          return;
        }
      } else if (supplier.hasOwnProperty('assetIDstatus')) {
        let assetIDStatusMain = supplier.assetIDstatus.toLowerCase();
        if (supplier.hasOwnProperty('assetIDStatus')) {
          let assetIDStatusSub = supplier.assetIDStatus.toLowerCase();

          if (assetIDStatusSub !== 'active') {
            return;
          } else if (assetIDStatusMain !== 'active') {
            return;
          }
        }

        if (assetIDStatusMain !== 'active') {
          return;
        }
      }
      suppliers.count += 1;

      switch (supplier.assetRisk.toLowerCase()) {
        case 'low':
          suppliers.low.count += 1;
          suppliers.low.percentage =
            ((suppliers.low.count / totalSuppliers) * 100).toFixed(2) + '%';
          suppliers.low.suppliers.push(supplier);
          break;
        case 'medium':
          suppliers.medium.count += 1;
          suppliers.medium.percentage =
            ((suppliers.medium.count / totalSuppliers) * 100).toFixed(2) + '%';
          suppliers.medium.suppliers.push(supplier);
          break;
        case 'high':
          suppliers.high.count += 1;
          suppliers.high.percentage =
            ((suppliers.high.count / totalSuppliers) * 100).toFixed(2) + '%';
          suppliers.high.suppliers.push(supplier);
          break;
        case 'extreme':
          suppliers.extreme.count += 1;
          suppliers.extreme.percentage =
            ((suppliers.extreme.count / totalSuppliers) * 100).toFixed(2) + '%';
          suppliers.extreme.suppliers.push(supplier);
          break;
        default:
          break;
      }
    });
  }

  return suppliers;
};

window.getRisks = async (customerID = window.companyID) => {
  const risksRef = db.doc(`customers/${customerID}/compliance/risk`);
  const snapshot = await risksRef.get();

  const risks = {
    totalCount: 0,
    low: {
      count: 0,
      percentage: 0,
      risks: [],
    },
    medium: {
      count: 0,
      percentage: 0,
      risks: [],
    },
    high: {
      count: 0,
      percentage: 0,
      risks: [],
    },
    extreme: {
      count: 0,
      percentage: 0,
      risks: [],
    },
  };

  const totalRisks = Object.keys(snapshot.data().register.risks).length;
  risks.totalCount = totalRisks;

  if (snapshot.exists) {
    const risksDocument = snapshot.data().register.risks;

    Object.keys(risksDocument).forEach(async (riskID) => {
      let risk = risksDocument[riskID];

      if (
        risk.hasOwnProperty('inherentLike') &&
        risk.hasOwnProperty('inherentCons') &&
        risk.hasOwnProperty('controlEffect')
      ) {
        let inherentLike = risk.inherentLike.split(' - ')[1];
        let inherentCons = risk.inherentCons.split(' - ')[1];
        let controlEffect = risk.controlEffect.split(' - ')[1];
        const residualRisk = Math.ceil(inherentLike * inherentCons * controlEffect);
        // console.log(
        //   'Risk Loading',
        //   `\nInherent Likelihood: ${inherentLike}`,
        //   `\nInherent Consequence: ${inherentCons}`,
        //   `\nControl Effectiveness: ${controlEffect}`,
        //   `\nResidual Risk: ${residualRisk}`,
        //   risk,
        // );

        if (residualRisk < 7) {
          risks.low.count += 1;
          risks.low.percentage = ((risks.low.count / totalRisks) * 100).toFixed(2) + '%';
          risks.low.risks.push(risk);
        } else if (residualRisk >= 7 && residualRisk < 13) {
          risks.medium.count += 1;
          risks.medium.percentage = ((risks.medium.count / totalRisks) * 100).toFixed(2) + '%';
          risks.medium.risks.push(risk);
        } else if (residualRisk >= 13 && residualRisk < 19) {
          risks.high.count += 1;
          risks.high.percentage = ((risks.high.count / totalRisks) * 100).toFixed(2) + '%';
          risks.high.risks.push(risk);
        } else if (residualRisk >= 19 && residualRisk <= 25) {
          risks.extreme.count += 1;
          risks.extreme.percentage = ((risks.extreme.count / totalRisks) * 100).toFixed(2) + '%';
          risks.extreme.risks.push(risk);
        }
      }
    });
  }

  return risks;
};

window.populateHeatmap = async () => {
  const numberToWord = {
    0: 'One',
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Five',
    6: 'One',
    7: 'Two',
    8: 'Three',
    9: 'Four',
    10: 'Five',
    11: 'One',
    12: 'Two',
    13: 'Three',
    14: 'Four',
    15: 'Five',
    16: 'One',
    17: 'Two',
    18: 'Three',
    19: 'Four',
    20: 'Five',
    21: 'One',
    21: 'Two',
    23: 'Three',
    24: 'Four',
    25: 'Five',
  };

  const risksRef = db.doc(`customers/${window.companyID}/compliance/risk`);
  const snapshot = await risksRef.get();

  // const totalRisks = Object.keys(snapshot.data().register.risks).length;

  if (snapshot.exists) {
    const risksDocument = snapshot.data().register.risks;

    Object.keys(risksDocument).forEach(async (riskID) => {
      let risk = risksDocument[riskID];

      if (
        risk.hasOwnProperty('inherentLike') &&
        risk.hasOwnProperty('inherentCons') &&
        risk.hasOwnProperty('controlEffect')
      ) {
        let inherentLike = risk.inherentLike.split(' - ')[1];
        let inherentCons = risk.inherentCons.split(' - ')[1];
        let controlEffect = risk.controlEffect.split(' - ')[1];
        let reduction = risk?.reduction ? risk.reduction : 'likelihood';
        let residualRisk = Math.ceil(inherentLike * inherentCons * controlEffect);
        console.log(
          'Risk Loading',
          `\nInherent Likelihood: ${inherentLike}`,
          `\nInherent Consequence: ${inherentCons}`,
          `\nControl Effectiveness: ${controlEffect}`,
          `\nReduction: ${reduction}`,
          `\nResidual Risk: ${residualRisk}`,
          risk,
        );

        let ICXCE = Math.ceil(inherentCons * controlEffect);
        let ILXCE = Math.ceil(inherentLike * controlEffect);

        console.log('Heatmap Data', {
          residualRisk,
          ICXCE,
          ILXCE,
        });

        let cellClass;
        if (reduction === 'likelihood') {
          cellClass = `.${numberToWord[residualRisk]}X${numberToWord[ILXCE]}`;
        } else if (reduction === 'consequence') {
          cellClass = `.${numberToWord[ICXCE]}X${numberToWord[residualRisk]}`;
        }

        console.log(`Risk ID: ${riskID} - ${cellClass}`);

        const cell = document.querySelector(cellClass);

        // Update the cell content with the count of risks
        try {
          if (cell !== undefined && cell !== null) {
            const count = parseInt(cell.textContent) || 0;
            cell.textContent = count + 1;
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }
};

/**
 * Converts a date string to a Unix timestamp.
 * @param {string} dateStr - The date string in the format 'YYYY-MM-DD HH:mm:ss'.
 * @returns {number} The Unix timestamp.
 */
function dateToUnix(dateStr) {
  const date = new Date(dateStr);
  return Math.floor(date.getTime() / 1000);
}

/**
 * Converts a Unix timestamp to a formatted date string.
 * @param {number} unixTimestamp - The Unix timestamp.
 * @param {string} format - The desired date format (e.g., 'YYYY-MM-DD HH:mm:ss').
 * @returns {string} The formatted date string.
 */
function unixToDate(unixTimestamp, format = 'YYYY-MM-DD HH:mm:ss') {
  const date = new Date(unixTimestamp * 1000);
  return date;
  const pad = (num) => num.toString().padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  let formattedDate = format
    .replace('YYYY', year)
    .replace('MM', month)
    .replace('DD', day)
    .replace('HH', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);

  return formattedDate;
}

// Example usage
// updateHeatmap(5, 4, 0.25);

function generateDates() {
  const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1); // Start from the beginning of the current year
  const endDate = new Date(currentYear, 11, 31); // End at the last day of the current year

  // Randomly select a start date within the current year
  const randomStartDate = new Date(
    startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()),
  );

  // Ensure end date is between 1 and 365 days after start date, but within the same year
  let randomEndDate;
  do {
    const daysToAdd = Math.floor(Math.random() * 365) + 1;
    randomEndDate = new Date(randomStartDate.getTime());
    randomEndDate.setDate(randomEndDate.getDate() + daysToAdd);
  } while (randomEndDate.getFullYear() !== currentYear);

  return {
    startDate: randomStartDate,
    endDate: randomEndDate,
  };
}

function addRandomDecimal(number) {
  if (number === 3) return number;
  // Generate a random decimal part
  let randomDecimal = Math.random() * 0.8;

  // Combine the input number with the random decimal
  let result = number + randomDecimal;

  // Round the result to a fixed number of decimal places, for example, 10
  result = result.toFixed(10);

  // Convert the result back to a number
  return parseFloat(result);
}

// Function to get RFFR Controls by Status
window.getRFFRControlsByStatus = async (status = 'Not Assessed') => {
  const controlsRef = db.collection('compliance').doc('ism').collection('controls');
  const customerDocRef = db
    .collection('customers')
    .doc(window.companyID)
    .collection('product')
    .doc('policyPackages')
    .collection('ISO27001keyDocuments')
    .doc('ismsSoa')
    .collection('ism');

  const queryOS = controlsRef.where('OS', '==', 'Yes').get();
  const queryO = controlsRef.where('o', '==', 'Yes').get();
  const queryCustomer = customerDocRef.get();

  try {
    // Get total controls count
    const totalControlsSnapshot = await controlsRef.get();
    const totalControlsCount = totalControlsSnapshot.size;

    const querySnapshots = await Promise.all([queryOS, queryO, queryCustomer]);
    const [querySnapshotOS, querySnapshotO, customerSnapshot] = querySnapshots;

    const controls = {
      count: 0,
      totalControlCount: totalControlsCount,
      list: [],
    };
    const processedControlIDs = new Set();

    // Process controls from customerDocRef
    customerSnapshot.forEach((doc) => {
      if (!processedControlIDs.has(doc.id)) {
        console.log('Found ' + doc.id + ' in customer');
        processedControlIDs.add(doc.id);
        processDocument(doc, controls, status);
      } else {
        console.log('Found ' + doc.id + ' in customer - removing from controlArray and replacing');
        // If controlID already exists, prefer the one from customerDocRef
        updateExistingControl(doc, controls, status);
        console.log(controls);
      }
    });

    // Process controls from controlsRef
    querySnapshotOS.forEach((doc) => {
      if (!processedControlIDs.has(doc.id)) {
        processedControlIDs.add(doc.id);
        processDocument(doc, controls, status);
      }
    });

    querySnapshotO.forEach((doc) => {
      if (!processedControlIDs.has(doc.id)) {
        processedControlIDs.add(doc.id);
        processDocument(doc, controls, status);
      }
    });

    return controls;
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  // Function to process each document
  function processDocument(doc, controls, status) {
    // console.log(`Found RFFR Control ${doc.id}`);
    let implementationStatus = doc.data().implementationStatus || 'Not Assessed';

    if (
      status.toLowerCase() !== 'controls implemented' &&
      implementationStatus.toLowerCase() !== status.toLowerCase()
    ) {
      // Skip adding this control if the implementationStatus doesn't match the given status
      return;
    }

    controls.count += 1;

    let startDate = convertTimestampToDate(doc.data().implementationStartDate);
    let endDate = convertTimestampToDate(doc.data().implementationEndDate);
    let randomDates = generateDates();
    if (startDate === null) {
      startDate = randomDates['startDate'];
    }

    if (endDate === null) {
      endDate = randomDates['endDate'];
    }

    // Generate random offsets for startDate and endDate separately
    let randomOffsetStartDate = Math.floor(Math.random() * 20 * Math.random()) - 7;
    let randomOffsetEndDate = Math.floor(Math.random() * 34) - 7;

    console.log(startDate, endDate);
    // Add the random offsets to startDate and endDate
    startDate.setDate(startDate.getDate() + randomOffsetStartDate);
    endDate.setDate(endDate.getDate() + randomOffsetEndDate);

    const tempControl = {
      controlID: doc.id,
      status: implementationStatus,
      description: doc.data().description,
      guideline: doc.data().guideline,
      section: doc.data().section,
      topic: doc.data().topic,
      startDate: startDate,
      endDate: endDate,
      targetStatus: doc.data().hasOwnProperty('implementationStatus')
        ? doc.data().implementationStatus
        : implementationStatus,
    };

    controls.list.push(tempControl);

    console.log(tempControl);
  }

  // Function to update an existing control with data from customerDocRef
  function updateExistingControl(doc, controls, status) {
    const existingControlIndex = controls.list.findIndex((control) => control.controlID === doc.id);
    if (existingControlIndex !== -1) {
      const implementationStatus = doc.data().implementationStatus || 'Not Assessed';

      // Update the existing control with data from customerDocRef
      console.log('Updating Control', doc.id);
      let startDate = convertTimestampToDate(doc.data().implementationStartDate);
      let endDate = convertTimestampToDate(doc.data().implementationEndDate);

      let randomDates = generateDates();
      if (startDate === null) {
        startDate = randomDates['startDate'];
      }

      if (endDate === null) {
        endDate = randomDates['endDate'];
      }

      // Generate random offsets for startDate and endDate separately
      let randomOffsetStartDate = Math.floor(Math.random() * 25) - 7;
      let randomOffsetEndDate = Math.floor(Math.random() * 25) - 7;

      // Add the random offsets to startDate and endDate
      startDate.setDate(startDate.getDate() + randomOffsetStartDate);
      endDate.setDate(endDate.getDate() + randomOffsetEndDate);
      controls.list[existingControlIndex] = {
        controlID: doc.id,
        status: implementationStatus,
        description: doc.data().description,
        guideline: doc.data().guideline,
        section: doc.data().section,
        topic: doc.data().topic,
        startDate: startDate,
        endDate: endDate,
        targetStatus: doc.data().hasOwnProperty('implementationStatus')
          ? doc.data().implementationStatus
          : implementationStatus,
      };
    }
  }

  // Function to convert Firebase Timestamp to JavaScript Date
  function convertTimestampToDate(timestamp) {
    if (timestamp && timestamp instanceof FirebaseTimestamp) {
      return timestamp.toDate();
    }
    return null; // or handle accordingly if timestamp is not present or not a valid Firebase Timestamp
  }
};

// Function to convert response to dataset
function convertControlsToDataset(controlsResponse, expectedStatus) {
  const dataset = {
    Total: {},
    [expectedStatus]: {},
    Other: {},
    counts: {
      total: controlsResponse.totalControlCount,
      [`${expectedStatus}Count`]: controlsResponse.count,
      notImplementedCount: controlsResponse.totalControlCount - controlsResponse.count,
    },
  };

  controlsResponse.list.forEach((control) => {
    const controlID = control.controlID;
    const status = control.status;

    // Assuming '0.117', '0.234', etc. are placeholder values. Replace with actual logic if needed.
    const value = '0.117';

    dataset.Total[controlID] = value;

    if (status.toLowerCase() === expectedStatus.toLowerCase()) {
      dataset[expectedStatus][controlID] = value;
    } else {
      dataset['Other'][controlID] = value;
    }
  });

  return dataset;
}

// Function to convert dataset to array format
function convertDatasetToArray(dataset, expectedStatus) {
  return [
    [expectedStatus, dataset.counts[`${expectedStatus}Count`]],
    ['Other', dataset.counts.notImplementedCount],
  ];
}

window.rffrDonutControls = async (
  containerID,
  type = 'Not Assessed',
  customerID = window.companyID,
) => {
  const startYear = 'ISM-0840',
    endYear = 2020,
    btn = document.getElementById('play-pause-button'),
    input = document.getElementById('play-range'),
    nbr = 6;

  let dataset, chart;

  function getData(year) {
    const output = Object.entries(dataset).map((control) => {
      console.log(control);
      const [controlName, controlStatus] = control;
      return [controlName, Number(controlStatus[year])];
    });
    return [output[0], output.slice(1, nbr)];
  }

  // function getSubtitle() {
  //   return `<center><span style="font-size: 24px">500</span>
  //       <br>
  //       <span style="font-size: 13px">
  //           Total: <b> 1278</b> Controls
  //       </span></center>`;
  // }

  (async () => {
    getRFFRControlsByStatus(type).then((controlsResponse) => {
      console.log('Controls Response', controlsResponse);
      const dataset = convertControlsToDataset(controlsResponse, type);
      const dataArray = convertDatasetToArray(dataset, type);
      console.log('Dataset', dataset);
      console.log('Data array', dataArray);

      chart = Highcharts.chart(containerID, {
        chart: {
          height: 225,
          width: 335,
        },
        title: {
          text: null,
        },
        menu: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        // subtitle: {
        //   useHTML: true,
        //   text: getSubtitle(),
        //   floating: true,
        //   verticalAlign: 'middle',
        //   y: 30,
        // },

        legend: {
          enabled: true,
        },

        tooltip: {
          valueDecimals: 0,
          valueSuffix: ' Controls',
        },

        plotOptions: {
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: false,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '16px',
              },
              connectorWidth: 0,
            },
          },
        },
        colors: ['#07B824', '#FFD325'],
        series: [
          {
            type: 'pie',
            name: 'Controls',
            data: dataArray,
          },
          // {
          //   type: 'pie',
          //   name: 'Not implemented',
          //   data: [['Total', 1278]],
          // },
        ],
      });

      // console.log(getData(startYear));
    });
  })();
};

window.rffrDemoScatterWidget = async (customerID = window.companyID) => {
  Highcharts.setOptions({
    colors: [
      'rgba(0, 153, 241, 0.5)',
      'rgba(7, 184, 36, 0.5)',
      'rgba(216, 9, 15, 0.5)',
      'rgba(246, 150, 29, 0.5)',
    ],
  });

  const series = [];

  async function getData() {
    const controlsRef = db.collection('compliance').doc('ism').collection('controls');
    const queryOS = controlsRef.where('OS', '==', 'Yes').get();
    const queryO = controlsRef.where('o', '==', 'Yes').get();

    try {
      const querySnapshots = await Promise.all([queryOS, queryO]);
      const [querySnapshotOS, querySnapshotO] = querySnapshots;
      const controls = [];
      const processedDocs = new Set();

      querySnapshotOS.forEach((doc) => {
        if (!processedDocs.has(doc.id)) {
          processedDocs.add(doc.id);
          processDocument(doc, controls);
        }
      });

      querySnapshotO.forEach((doc) => {
        if (!processedDocs.has(doc.id)) {
          processedDocs.add(doc.id);
          processDocument(doc, controls);
        }
      });

      return controls;
    } catch (error) {
      console.error('Error getting documents: ', error);
    }

    // Function to process each document
    function processDocument(doc, controls) {
      // console.log(`Found RFFR Control ${doc.id}`);
      let implementationStatus =
        doc.data().implementationStatus !== undefined ? doc.data().implementationStatus : 1;

      if (typeof implementationStatus === 'string') {
        switch (implementationStatus.toLowerCase()) {
          case 'not assessed':
          case 'not applicable':
            implementationStatus = 0;
            break;
          case 'not implemented':
            implementationStatus = 1;
            break;
          case 'partially implemented':
            implementationStatus = 2;
            break;
          case 'fully implemented':
            implementationStatus = 3;
            break;
          case 'out of bounds':
            implementationStatus = 4;
            break;
        }
      }

      const randomStatus = Math.floor(Math.random() * 4);

      if (randomStatus === 0) {
        // Skip adding this control if the random status is 0
        return;
      }

      const dates = generateDates();
      const tempControlStart = {
        controlID: doc.id,
        status: implementationStatus,
        time: dateToUnix(dates.startDate),
        dateString: dates.startDate,
        name: 'Implementation Start Date',
      };

      const tempControlEnd = {
        controlID: doc.id,
        status: randomStatus,
        time: dateToUnix(dates.endDate),
        dateString: dates.endDate,
        name: 'Implementation End Date',
      };

      controls.push(tempControlStart);
      controls.push(tempControlEnd);
    }
  }

  let colorSet = {
    0: 'rgba(216, 9, 15, 0.5)',
    1: 'rgba(0, 153, 241, 0.5)',
    2: 'rgba(246, 150, 29, 0.5)',
    3: 'rgba(7, 184, 36, 0.5)',
  };

  getData().then((data) => {
    data.forEach((elm) => {
      if (elm.status !== undefined && elm.time !== null) {
        let tempSeriesItem = {
          name: elm.controlID,
          id: elm.controlID,
          marker: {
            symbol: 'circle',
          },
          controlID: elm.controlID,
          dateString: new Date(),
          tooltipText: elm.name,
          status: elm.status,
          time: elm.time,
          data: [],
          color: colorSet[elm.status],
        };
        tempSeriesItem.data.push([elm.time, addRandomDecimal(elm.status), 10]);

        series.push(tempSeriesItem);
      }
    });

    console.log('Scatter Series', series);

    var obj = {
      0: 'Not applicable',
      1: 'Not implemented',
      2: 'Partially implemented',
      3: 'Fully implemented',
    };

    Highcharts.chart('RFFRControlsScatterWidget', {
      chart: {
        type: 'scatter',
        zooming: {
          type: 'xy',
        },
        animation: false,
      },
      title: {
        text: null,
      },
      menu: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },

      xAxis: {
        title: {
          text: 'Time',
        },
        labels: {
          formatter: function () {
            let date = unixToDate(this.value, 'MM/YYYY');
            let options = {
              month: 'short',
              year: 'numeric',
            };
            date = date.toLocaleDateString('en-AU', options);
            return date;
          },
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        title: {
          text: 'Status (Current Control Status)',
        },
        labels: {
          formatter: function () {
            return obj[this.value];
          },
        },
        tickPositions: [0, 1, 2, 3],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          shadow: false,
        },
        scatter: {
          marker: {
            radius: 2,
            symbol: 'circle',
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
              },
            },
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          showInLegend: false,
          jitter: {
            x: 0.24,
            y: 0,
          },
        },
      },
      tooltip: {
        pointFormatter: function () {
          return `${this.series.userOptions.tooltipText}: <b>${scatterDate(this.x)}</b><br/>Implementation Status: <b>${obj[this.series.userOptions.status]}</b><br/>`;
        },
        animation: false,
      },
      series,
    });
  });
};

const scatterDate = (date) => {
  let scatterDate = unixToDate(date, 'MM/YYYY');
  let options = {
    // month: 'short',
    // year: 'numeric'
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  scatterDate = scatterDate.toLocaleDateString('en-AU', options);
  return scatterDate;
};

window.rffrScatterWidget = async (customerID = window.companyID) => {
  Highcharts.setOptions({
    colors: [
      'rgba(0, 153, 241, 0.5)',
      'rgba(7, 184, 36, 0.5)',
      'rgba(216, 9, 15, 0.5)',
      'rgba(246, 150, 29, 0.5)',
    ],
  });

  const series = [];
  const colorSet = {
    0: 'rgba(216, 9, 15, 0.5)',
    1: 'rgba(0, 153, 241, 0.5)',
    2: 'rgba(246, 150, 29, 0.5)',
    3: 'rgba(7, 184, 36, 0.5)',
  };

  async function getData() {
    try {
      const controls = await window.getRFFRControlsByStatus('Controls Implemented');
      const data = [];

      controls.list.forEach((elm) => {
        const randomStatus = Math.floor(Math.random() * 4);

        if (randomStatus === 0) {
          // Skip adding this control if the random status is 0
          return;
        }

        const dates = generateDates();
        // const tempControlStart = {
        //     controlID: elm.controlID,
        //     status: mapImplementationStatus(elm.status), // Mapping status here
        //     time: dateToUnix(elm.startDate !== undefined && elm.startDate !== null ? elm.startDate : dates.startDate),
        //     dateString: elm.startDate !== undefined && elm.startDate !== null ? elm.startDate : dates.startDate,
        //     name: 'Implementation Start Date',
        // };

        const tempControlEnd = {
          controlID: elm.controlID,
          status:
            elm.hasOwnProperty('targetStatus') &&
            mapImplementationStatus(elm.targetStatus) !== mapImplementationStatus(elm.status)
              ? mapImplementationStatus(elm.targetStatus)
              : randomStatus,
          time: dateToUnix(
            elm.endDate !== undefined && elm.endDate !== null ? elm.endDate : dates.endDate,
          ),
          dateString:
            elm.endDate !== undefined && elm.endDate !== null ? elm.endDate : dates.endDate,
          name: 'Implementation End Date',
        };

        // data.push(tempControlStart);
        data.push(tempControlEnd);
      });

      return data;
    } catch (error) {
      console.error('Error getting RFFR controls data: ', error);
      return [];
    }
  }

  getData().then((data) => {
    data.forEach((elm) => {
      if (elm.status !== undefined && elm.time !== null) {
        let tempSeriesItem = {
          name: elm.controlID,
          id: elm.controlID,
          marker: {
            symbol: 'circle',
          },
          controlID: elm.controlID,
          dateString: new Date(),
          tooltipText: elm.name,
          status: elm.status,
          time: elm.time,
          data: [],
          color: colorSet[elm.status],
        };
        tempSeriesItem.data.push([elm.time, addRandomDecimal(elm.status), 10]);

        series.push(tempSeriesItem);
      }
    });

    console.log('Scatter Series', series);

    var obj = {
      0: 'Not applicable',
      1: 'Not implemented',
      2: 'Partially implemented',
      3: 'Fully implemented',
    };

    Highcharts.chart('RFFRControlsScatterWidget', {
      chart: {
        type: 'scatter',
        zooming: {
          type: 'xy',
        },
        animation: false,
      },
      title: {
        text: null,
      },
      menu: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },

      xAxis: {
        title: {
          text: 'Time',
        },
        labels: {
          formatter: function () {
            let date = unixToDate(this.value, 'MM/YYYY');
            let options = {
              month: 'short',
              year: 'numeric',
            };
            date = date.toLocaleDateString('en-AU', options);
            return date;
          },
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        title: {
          text: 'Status (Current Control Status)',
        },
        labels: {
          formatter: function () {
            return obj[this.value];
          },
        },
        tickPositions: [0, 1, 2, 3],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          shadow: false,
        },
        scatter: {
          marker: {
            radius: 2,
            symbol: 'circle',
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
              },
            },
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          showInLegend: false,
          jitter: {
            x: 0.24,
            y: 0,
          },
        },
      },
      tooltip: {
        pointFormatter: function () {
          return `${this.series.userOptions.tooltipText}: <b>${scatterDate(this.x)}</b><br/>Implementation Status: <b>${obj[this.series.userOptions.status]}</b><br/>`;
        },
        animation: false,
      },
      series,
    });
  });
};

// Function to map implementation status string to numeric code
function mapImplementationStatus(status) {
  switch (status.toLowerCase()) {
    case 'not applicable':
      return 0;
    case 'not assessed':
    case 'not implemented':
      return 1;
    case 'partially implemented':
      return 2;
    case 'fully implemented':
      return 3;
    case 'out of bounds':
      return 4;
    default:
      return 1; // Default to 'Not implemented' if status is unknown
  }
}

window.getTaskAssignments = async (customerID = window.companyID) => {
  const assuranceTasksRef = db.doc(`customers/${customerID}/compliance/assurance`);
  const snapshot = await assuranceTasksRef.get();

  const assignees = {};
  const totalTasks = Object.keys(snapshot.data()).length;

  if (snapshot.exists) {
    const assuranceTasks = snapshot.data();

    Object.keys(assuranceTasks).forEach(async (assID) => {
      let assuranceTask = assuranceTasks[assID];
      if (
        assuranceTask.hasOwnProperty('checkDetail') &&
        assuranceTask.checkDetail.hasOwnProperty('assignedTo')
      ) {
        if (assignees.hasOwnProperty(assuranceTask.checkDetail.assignedTo)) {
          assignees[assuranceTask.checkDetail.assignedTo]['count'] += 1;
          assignees[assuranceTask.checkDetail.assignedTo]['percentage'] =
            (assignees[assuranceTask.checkDetail.assignedTo]['count'] / totalTasks) * 100;
        } else {
          assignees[assuranceTask.checkDetail.assignedTo] = {};
          assignees[assuranceTask.checkDetail.assignedTo]['count'] = 1;
          // await window.nameFromUID(assuranceTask.checkDetail.assignedTo).then((name) => {
          //   assignees[assuranceTask.checkDetail.assignedTo]['name'] = name;
          // });
          assignees[assuranceTask.checkDetail.assignedTo]['name'] = await window.nameFromUID(
            assuranceTask.checkDetail.assignedTo,
          );
          // Calculate percentage of tasks assigned to each user
          assignees[assuranceTask.checkDetail.assignedTo]['percentage'] =
            (assignees[assuranceTask.checkDetail.assignedTo]['count'] / totalTasks) * 100;
        }
      }
    });
  }

  return assignees;
};

window.getTasksLogged = async (customerID = window.companyID) => {
  const assuranceTasksRef = db.doc(`customers/${customerID}/compliance/assurance`);
  const snapshot = await assuranceTasksRef.get();

  const tasksAdded30DaysAfterLastCheckDate = [];

  if (snapshot.exists) {
    const assuranceTasks = snapshot.data();

    Object.keys(assuranceTasks).forEach((assID) => {
      let assuranceTask = assuranceTasks[assID];

      if (
        assuranceTask.hasOwnProperty('checkDetail') &&
        assuranceTask.checkDetail.hasOwnProperty('createdDate') &&
        assuranceTask.hasOwnProperty('checkFrequency') &&
        assuranceTask.checkFrequency.hasOwnProperty('lastCheck') &&
        Object.keys(assuranceTask.checkFrequency.lastCheck).length > 0
      ) {
        let createdDate = new Date(assuranceTask.checkDetail.createdDate.toDate());
        let lastCheckKey = Object.keys(assuranceTask.checkFrequency.lastCheck)[
          Object.keys(assuranceTask.checkFrequency.lastCheck).length - 1
        ];
        if (
          Object.keys(assuranceTask.checkFrequency.lastCheck)[
            Object.keys(assuranceTask.checkFrequency.lastCheck).length - 1
          ] === 'undefined'
        ) {
          lastCheckKey = Object.keys(assuranceTask.checkFrequency.lastCheck)[
            Object.keys(assuranceTask.checkFrequency.lastCheck).length - 2
          ];
        }
        let lastCheckDate = new Date(
          assuranceTask.checkFrequency.lastCheck[lastCheckKey].checkDate.toDate(),
        );

        // Check if lastCheckDate is over 30 days before createdDate
        let timeDiff = Math.abs(lastCheckDate.getTime() - createdDate.getTime());
        let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (daysDiff >= 30) {
          tasksAdded30DaysAfterLastCheckDate.push(assuranceTask);
        }

        // Object.keys(assuranceTask.history).forEach((historyIndex) => {
        //   let historyItem = assuranceTask.history[historyIndex];
        //   if (historyItem.hasOwnProperty('fieldsChanged')) {
        //     Object.keys(historyItem.fieldsChanged).forEach((fieldIndex) => {
        //       let field = historyItem.fieldsChanged[fieldIndex];
        //       if (
        //         field.fieldName === 'Next Check' &&
        //         field.newValue !== undefined &&
        //         field.newValue !== '' &&
        //         field.oldValue !== undefined &&
        //         field.oldValue !== ''
        //       ) {
        //
        //         // Check if newValue was changed within 30 days of oldValue
        //         let newValue = field.newValue.toDate();
        //         let oldValue = field.oldValue.toDate();
        //         let timeDiff = Math.abs(newValue.getTime() - oldValue.getTime());
        //         let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        //
        //         if (daysDiff <= 30) {
        //           assuranceTasksWithNextCheckChanged.push(assuranceTask);
        //         }
        //         // assuranceTasksWithNextCheckChanged.push(assuranceTask);
        //       }
        //     });
        //   }
        // });
      }
    });

    const totalAssuranceTasks = Object.keys(assuranceTasks).length;
    const tasksWithNextCheckChangedPercentage =
      (tasksAdded30DaysAfterLastCheckDate.length / totalAssuranceTasks) * 100;
    // document.querySelector('.RescheduledTasksPercentage .count').textContent =
    //   tasksWithNextCheckChangedPercentage.toFixed(2) + '%';
    return {
      tasksAdded30DaysAfterLastCheckDate,
      tasksWithNextCheckChangedPercentage,
    };
  }
};

window.getAssuranceTasksWithNextCheckChanged = async (customerID = window.companyID) => {
  const assuranceTasksRef = db.doc(`customers/${customerID}/compliance/assurance`);
  const snapshot = await assuranceTasksRef.get();

  const assuranceTasksWithNextCheckChanged = [];

  if (snapshot.exists) {
    const assuranceTasks = snapshot.data();

    Object.keys(assuranceTasks).forEach((assID) => {
      let assuranceTask = assuranceTasks[assID];

      if (assuranceTask.history) {
        Object.keys(assuranceTask.history).forEach((historyIndex) => {
          let historyItem = assuranceTask.history[historyIndex];
          if (historyItem.hasOwnProperty('fieldsChanged')) {
            Object.keys(historyItem.fieldsChanged).forEach((fieldIndex) => {
              let field = historyItem.fieldsChanged[fieldIndex];
              if (
                field.fieldName === 'Next Check' &&
                field.newValue !== undefined &&
                field.newValue !== '' &&
                field.oldValue !== undefined &&
                field.oldValue !== ''
              ) {
                // Check if newValue was changed within 30 days of oldValue
                let newValue = field.newValue.toDate();
                let oldValue = field.oldValue.toDate();
                let timeDiff = Math.abs(newValue.getTime() - oldValue.getTime());
                let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

                if (daysDiff <= 30) {
                  assuranceTasksWithNextCheckChanged.push(assuranceTask);
                }
                // assuranceTasksWithNextCheckChanged.push(assuranceTask);
              }
            });
          }
        });
      }
    });

    const totalAssuranceTasks = Object.keys(assuranceTasks).length;
    const tasksWithNextCheckChangedPercentage =
      (assuranceTasksWithNextCheckChanged.length / totalAssuranceTasks) * 100;
    // document.querySelector('.RescheduledTasksPercentage .count').textContent =
    //   tasksWithNextCheckChangedPercentage.toFixed(2) + '%';
    return {
      assuranceTasksWithNextCheckChanged,
      tasksWithNextCheckChangedPercentage,
    };
  }
};

window.getRequestCounts = (data) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let monthCount = 0;
  let yearCount = 0;

  // Loop through each property in the object
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const requests = data[key].requests;

      // Loop through each request in the array
      for (const request of requests) {
        const requestDate = new Date(request.requestDate.seconds * 1000);
        const requestMonth = requestDate.getMonth();
        const requestYear = requestDate.getFullYear();

        // Check if the request is from the current month
        if (requestMonth === currentMonth && requestYear === currentYear) {
          monthCount++;
        }

        // Check if the request is from the current year
        if (requestYear === currentYear) {
          yearCount++;
        }
      }
    }
  }

  // Create the JSON object with the counts
  const result = {
    monthCount: monthCount,
    yearCount: yearCount,
  };

  return result;
};

function mergeObjects(obj1, obj2) {
  const mergedObj = {};

  for (let key in obj1) {
    if (typeof obj1[key] === 'number') {
      // If the key exists in both objects and is a number, add them up
      if (obj2.hasOwnProperty(key) && typeof obj2[key] === 'number') {
        mergedObj[key] = obj1[key] + obj2[key];
      } else {
        mergedObj[key] = obj1[key];
      }
    } else if (Array.isArray(obj1[key])) {
      // If the key exists in both objects and is an array, merge them
      if (obj2.hasOwnProperty(key) && Array.isArray(obj2[key])) {
        mergedObj[key] = obj1[key].concat(obj2[key]);
      } else {
        mergedObj[key] = obj1[key];
      }
    } else if (typeof obj1[key] === 'object') {
      // If the key exists in both objects and is an object, recursively merge
      if (obj2.hasOwnProperty(key) && typeof obj2[key] === 'object') {
        mergedObj[key] = mergeObjects(obj1[key], obj2[key]);
      } else {
        mergedObj[key] = obj1[key];
      }
    } else {
      // Otherwise, just copy the value
      mergedObj[key] = obj1[key];
    }
  }

  // Add keys from obj2 that don't exist in obj1
  for (let key in obj2) {
    if (!obj1.hasOwnProperty(key)) {
      mergedObj[key] = obj2[key];
    }
  }

  return mergedObj;
}

window.Export2PDF = async (element) => {
  element.innerHTML = '<i class="fa-duotone fa-spinner-third fa-spin"></i> Exporting...';

  let companyImg = document.querySelector('#companyImg').cloneNode(true);
  let titleCon = document.createElement('div');
  titleCon.style.display = 'flex';
  titleCon.style.flexDirection = 'column';
  titleCon.alignItems = 'center';
  titleCon.innerHTML = '<br><br>';
  let title = document.createElement('h2');
  title.textContent = 'Compliance Report for ' + window.tenancyName.textContent;
  title.style.width = 'auto';
  title.style.marginLeft = 'auto';
  title.style.marginRight = 'auto';
  companyImg.style.width = '120px';
  companyImg.style.marginLeft = 'auto';
  companyImg.style.marginRight = 'auto';
  titleCon.append(companyImg);
  titleCon.append(title);
  titleCon.innerHTML += '<br><br>';
  document.querySelector('#dashboard2_0').prepend(titleCon);

  document.querySelector('#dashboard2_0').style.background = '#f3f4f7';
  await html2pdf()
    .from(document.querySelector('#dashboard2_0'))
    .set({
      filename: `Compliance Report.pdf`,
      pagebreak: {
        mode: 'avoid-all',
      },
      html2canvas: {
        orientation: 'portrait',
        useCORS: true,
        allowTaint: false,
      },
      jsPDF: {
        orientation: 'portrait',
        unit: 'px',
        format: 'a1',
        hotfixes: ['px_scaling'],
      },
      margin: [5, 5, 5, 5],
    })
    .save()
    .then((res) => {
      console.log(res);
      element.innerHTML = '<i class="fa-duotone fa-check"></i> Exported';

      setTimeout(() => {
        element.innerHTML = '<i class="fa-duotone fa-up color-orange"></i> Export';
        titleCon.remove();
      });
    });
};

window.getAssuranceHealth = async () => {
  let total = [];
  let onTime = [];
  let overdue = [];
  let toBeCompletedOverdue = [];
  let upcoming = [];
  let completed = [];
  let totalTasks = 0;
  let selectedTask = 0;
  let totalControls = 0;
  let selectedControls = 0;

  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()
    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        for (const key of Object.keys(assuranceData).sort()) {
          totalTasks++;
          if (
            assuranceData[key].hasOwnProperty('checkDetail') &&
            assuranceData[key].checkDetail.hasOwnProperty('active') &&
            assuranceData[key].checkDetail.active === 'true'
          ) {
            selectedTask++;
            if (assuranceData[key]?.checkFrequency !== undefined) {
              let nextCheckDate = new Date(assuranceData[key].checkFrequency.nextCheck.toDate());
              let today = new Date().setHours(0, 0, 0, 0);

              let currentDate = new Date();

              const differenceInMilliseconds1 = nextCheckDate.getTime() - currentDate.getTime();

              // Convert milliseconds to days
              const differenceInDays1 = Math.ceil(differenceInMilliseconds1 / (1000 * 3600 * 24));

              // Check the status based on the difference in days and update the count
              if (differenceInDays1 <= 30 && differenceInDays1 >= 0) {
                upcoming.push(assuranceData[key]);
              }

              if (nextCheckDate < today) {
                toBeCompletedOverdue.push(assuranceData[key]);
              }

              // if (assuranceData[key].hasOwnProperty('checkHistory')) {
              //   completed.push(assuranceData[key]);
              // }
              let numberPreviousChecks = assuranceData[key].checkHistory.numberPreviousChecks;
              // let lastCheckDate = assuranceData[key].checkHistory[numberPreviousChecks].lastCheck.toDate();
              if (
                assuranceData[key].checkHistory?.[numberPreviousChecks]?.scheduledDate ===
                  undefined ||
                assuranceData[key].checkHistory?.[numberPreviousChecks]?.scheduledDate === ''
              ) {
                continue;
              }

              console.log(key);

              let scheduledDate = assuranceData[key].checkHistory[
                numberPreviousChecks
              ].scheduledDate
                .toDate()
                .setHours(0, 0, 0, 0);
              let performedDate = assuranceData[key].checkHistory[
                numberPreviousChecks
              ].performedDate
                .toDate()
                .setHours(0, 0, 0, 0);
              const is_before_or_equal_date = (date1, date2) => date1 <= date2;

              // Calculate the difference in milliseconds
              const differenceInMilliseconds =
                assuranceData[key].checkHistory[numberPreviousChecks].scheduledDate
                  .toDate()
                  .getTime() -
                assuranceData[key].checkHistory[numberPreviousChecks].performedDate
                  .toDate()
                  .getTime();

              // Convert milliseconds to days
              const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

              // Check the status based on the difference in days and update the count
              if (differenceInDays >= -365 && differenceInDays1 <= -30) {
                completed.push(assuranceData[key]);
              }

              total.push(assuranceData[key]);
              if (assuranceData[key].checkDetail.active === 'true') {
                if (is_before_or_equal_date(scheduledDate, performedDate)) {
                  onTime.push(assuranceData[key]);
                } else {
                  overdue.push(assuranceData[key]);
                }
              }
            }
          }
        }
      }
    });

  await db
    .collection('compliance')
    .doc('paControls')
    .get()
    .then((e8ControlsDoc) => {
      if (e8ControlsDoc.exists) {
        let e8Controls = e8ControlsDoc.data();
        Object.keys(e8Controls)
          .sort()
          .forEach((key) => {
            totalControls++;
            selectedControls++;
          });
      }
    });

  await db
    .collection('compliance')
    .doc('E8Controls')
    .get()
    .then((e8ControlsDoc) => {
      if (e8ControlsDoc.exists) {
        let e8Controls = e8ControlsDoc.data();
        Object.keys(e8Controls)
          .sort()
          .forEach((key) => {
            totalControls++;
            selectedControls++;
          });
      }
    });

  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('product')
    .doc('policyPackages')
    .collection('ISO27001keyDocuments')
    .doc('ismsSoa')
    .get()
    .then((ismsSoa) => {
      if (ismsSoa.exists) {
        let ismsSoaData = ismsSoa.data();
        Object.keys(ismsSoaData)
          .sort()
          .forEach((key) => {
            if (ismsSoaData[key].controlApplicable === false) {
              selectedControls = selectedControls - 1;
            }
          });
      }
    });

  return {
    total,
    onTime,
    overdue,
    toBeCompletedOverdue,
    upcoming,
    completed,
    totalTasks,
    selectedTask,
    totalControls,
    selectedControls,
    totalAssuranceCount: {
      selectedTask,
      totalTasks,
    },
    completionRate: {
      selectedControls,
      totalControls,
    },
    onTimePercentage: Math.round((onTime.length / total.length) * 100),
    overduePercentage: Math.round((overdue.length / total.length) * 100),
    toBeCompletedOverduePercentage: Math.round((toBeCompletedOverdue.length / total.length) * 100),
    upcomingPercentage: Math.round((upcoming.length / total.length) * 100),
    completedPercentage: Math.round((completed.length / total.length) * 100),
    onTimeCount: onTime.length,
    overdueCount: overdue.length,
    toBeCompletedOverdueCount: toBeCompletedOverdue.length,
    upcomingCount: upcoming.length,
    completedCount: completed.length,
  };
};
window.getAssuranceHealth = getAssuranceHealth;

window.getUserAccessAuditDetails = async () => {
  let assID = 'ass007';

  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()

    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (key === assID) {
              let nextCheckDate = assuranceData[key].checkFrequency.nextCheck.toDate();
              const currentDate = new Date();

              // Calculate the difference in milliseconds
              const differenceInMilliseconds = nextCheckDate.getTime() - currentDate.getTime();

              // Convert milliseconds to days
              const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
              document.querySelector('.UserAccessAudit .daysTill').textContent =
                `${differenceInDays} days`;

              let lastCheckDate =
                assuranceData[key].checkFrequency.lastCheck[
                  Object.keys(assuranceData[key].checkFrequency.lastCheck).length
                ].checkDate.toDate();

              document.querySelector('.UserAccessAudit .lastPerformed').textContent =
                lastCheckDate.toDateString();

              nameFromUID(assuranceData[key].checkDetail.assignedTo).then((name) => {
                document.querySelector('.UserAccessAudit .assignedTo').textContent = name;
              });
            }
          });
      }
    });
};
window.getInternalAuditData = async () => {
  let assID = 'ass051';

  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()

    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (key === assID) {
              let nextCheckDate = assuranceData[key].checkFrequency.nextCheck.toDate();
              const currentDate = new Date();

              // Calculate the difference in milliseconds
              const differenceInMilliseconds = nextCheckDate.getTime() - currentDate.getTime();

              // Convert milliseconds to days
              const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
              document.querySelector('.InternalAudit .daysTill').textContent =
                `${differenceInDays} days`;

              let lastCheckDate =
                assuranceData[key].checkFrequency.lastCheck[
                  Object.keys(assuranceData[key].checkFrequency.lastCheck).length
                ].checkDate.toDate();

              document.querySelector('.InternalAudit .lastPerformed').textContent =
                lastCheckDate.toDateString();

              nameFromUID(assuranceData[key].checkDetail.assignedTo).then((name) => {
                document.querySelector('.InternalAudit .assignedTo').textContent = name;
              });
            }
          });
      }
    });
};

window.getAssuranceTaskPercentages = async () => {
  return await db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()
    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        let totalTasks = Object.keys(assuranceData).length;
        let earlyCount = 0;
        let lateCount = 0;
        let veryLateCount = 0;
        let overdueCount = 0;

        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (!assuranceData[key].hasOwnProperty('checkFrequency')) {
              totalTasks--;
              return;
            }
            let nextCheckDate = assuranceData[key].checkFrequency.nextCheck.toDate();
            const currentDate = new Date();

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = nextCheckDate.getTime() - currentDate.getTime();

            // Convert milliseconds to days
            const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

            let lastCheckKey = Object.keys(assuranceData[key].checkFrequency.lastCheck)[
              Object.keys(assuranceData[key].checkFrequency.lastCheck).length - 1
            ];
            if (
              Object.keys(assuranceData[key].checkFrequency.lastCheck)[
                Object.keys(assuranceData[key].checkFrequency.lastCheck).length - 1
              ] === 'undefined'
            ) {
              lastCheckKey = Object.keys(assuranceData[key].checkFrequency.lastCheck)[
                Object.keys(assuranceData[key].checkFrequency.lastCheck).length - 2
              ];
            }
            if (
              assuranceData[key].checkFrequency.lastCheck[lastCheckKey].checkDate === '' ||
              assuranceData[key].checkFrequency.lastCheck[lastCheckKey].checkDate === undefined ||
              assuranceData[key].checkFrequency.lastCheck[lastCheckKey].checkDate === null
            )
              return;
            let lastCheckDate =
              assuranceData[key].checkFrequency.lastCheck[lastCheckKey].checkDate.toDate();

            // Check the status based on the difference in days and update the count
            if (differenceInDays > 0) {
              earlyCount++;
            } else if (differenceInDays >= -60) {
              lateCount++;
              overdueCount++;
            } else {
              veryLateCount++;
              overdueCount++;
            }
          });

        // Calculate the percentages
        let earlyPercentage = (earlyCount / totalTasks) * 100;
        let latePercentage = (lateCount / totalTasks) * 100;
        let veryLatePercentage = (veryLateCount / totalTasks) * 100;
        let overduePercentage = (overdueCount / totalTasks) * 100;

        // Log the percentages
        // console.log(`Early: ${earlyPercentage.toFixed(2)}%`);
        // console.log(`Late: ${latePercentage.toFixed(2)}%`);
        // console.log(`Very Late: ${veryLatePercentage.toFixed(2)}%`);
        // console.log('Overdue: ', overduePercentage.toFixed(2) + '%');

        // Display the percentages on the page
        return {
          earlyPercentage: earlyPercentage.toFixed(2),
          latePercentage: latePercentage.toFixed(2),
          veryLatePercentage: veryLatePercentage.toFixed(2),
          overduePercentage: overduePercentage.toFixed(2),
        };
      }
    });
};

async function getPAAssuranceHealth() {
  let total = [];
  let onTime = [];
  let overdue = [];
  let totalTasks = 0;
  let selectedTask = 0;
  let totalControls = 0;
  let selectedControls = 0;
  db.collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()
    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (assuranceData[key].checkDetail.framework[0].frameName === 'Privacy Act') {
              totalTasks++;
            }
            if (
              assuranceData[key].hasOwnProperty('checkDetail') &&
              assuranceData[key].checkDetail.hasOwnProperty('active') &&
              assuranceData[key].checkDetail.active === 'true'
            ) {
              if (assuranceData[key].checkDetail.framework[0].frameName === 'Privacy Act') {
                selectedTask++;
                let numberPreviousChecks = assuranceData[key].checkHistory.numberPreviousChecks;
                if (
                  assuranceData[key].checkHistory[numberPreviousChecks].scheduledDate === '' ||
                  assuranceData[key].checkHistory[numberPreviousChecks].performedDate === ''
                )
                  return;

                let scheduledDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].scheduledDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                let performedDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].performedDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                const is_before_or_equal_date = (date1, date2) => date1 <= date2;

                total.push(assuranceData[key]);
                if (is_before_or_equal_date(scheduledDate, performedDate)) {
                  onTime.push(assuranceData[key]);
                } else {
                  overdue.push(assuranceData[key]);
                }
              }
            }
          });
        // window.Highcharts.chart('privacyActAssuranceTaskChartDiv', {
        //   chart: {
        //     height: '225',
        //     type: 'variablepie',
        //   },
        //   exporting: {
        //     enabled: false,
        //   },
        //   tooltip: {
        //     enabled: false,
        //   },
        //   credits: {
        //     enabled: false,
        //   },
        //   title: {
        //     enabled: true,
        //     verticalAlign: 'middle',
        //     align: 'center',
        //     text: `100%</br> on-time`,
        //     style: {
        //       visibility: true,
        //       align: 'center',
        //       fontFamily: 'Arial',
        //       fontSize: '16px',
        //       color: 'grey',
        //     },
        //   },
        //   series: [
        //     {
        //       minPointSize: 10,
        //       innerSize: '80%',
        //       zMin: 0,
        //       dataLabels: {
        //         enabled: false,
        //       },
        //       name: 'Privacy Act Assurance Tasks',
        //       data: [
        //         {
        //           name: 'On Time',
        //           z: 60,
        //           y: 100,
        //           color: 'rgb(11, 83, 148)',
        //         },
        //         {
        //           name: 'Overdue',
        //           z: 60,
        //           y: 0,
        //           color: 'rgb(230, 145, 56)',
        //         },
        //       ],
        //     },
        //   ],
        // });
        // document.getElementById('privacyActTotalAssuranceCount').innerText =
        //   `${selectedTask}/${totalTasks}`;
        // document.getElementById('privacyActCompletionRate').innerText = '28/30';
      }
    });

  await db
    .collection('compliance')
    .doc('paControls')
    .get()
    .then((e8ControlsDoc) => {
      if (e8ControlsDoc.exists) {
        let e8Controls = e8ControlsDoc.data();
        Object.keys(e8Controls)
          .sort()
          .forEach((key) => {
            totalControls++;
            selectedControls++;
          });
      }
    });
  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('product')
    .doc('policyPackages')
    .collection('ISO27001keyDocuments')
    .doc('ismsSoa')
    .get()
    .then((ismsSoa) => {
      if (ismsSoa.exists) {
        let ismsSoaData = ismsSoa.data();
        Object.keys(ismsSoaData)
          .sort()
          .forEach((key) => {
            if (key.startsWith('PA')) {
              if (ismsSoaData[key].controlApplicable === false) {
                selectedControls = selectedControls - 1;
              }
            }
          });
      }
    });

  // document.getElementById('privacyActCompletionRate').innerText =
  //   `${selectedControls}/${totalControls}`;
  return {
    total,
    onTime,
    overdue,
    totalTasks,
    selectedTask,
    totalControls,
    selectedControls,
    totalAssuranceCount: {
      selectedTask,
      totalTasks,
    },
    completionRate: {
      selectedControls,
      totalControls,
    },
    onTimePercentage: Math.round((onTime.length / total.length) * 100),
    overduePercentage: Math.round((overdue.length / total.length) * 100),
  };
}

async function getE8AssuranceHealth() {
  let total = [];
  let onTime = [];
  let overdue = [];
  let totalTasks = 0;
  let selectedTask = 0;
  let totalControls = 0;
  let selectedControls = 0;

  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()
    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        let count = 0;
        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (
              assuranceData[key].checkDetail.hasOwnProperty('framework') &&
              assuranceData[key].checkDetail.framework[0].frameName === 'Essential 8'
            ) {
              totalTasks++;
            }
            if (
              assuranceData[key].hasOwnProperty('checkDetail') &&
              assuranceData[key].checkDetail.hasOwnProperty('active') &&
              assuranceData[key].checkDetail.active === 'true'
            ) {
              if (assuranceData[key].checkDetail.framework[0].frameName === 'Essential 8') {
                selectedTask++;
                let numberPreviousChecks = assuranceData[key].checkHistory.numberPreviousChecks;
                if (
                  assuranceData[key].checkHistory[numberPreviousChecks].scheduledDate === '' ||
                  assuranceData[key].checkHistory[numberPreviousChecks].performedDate === ''
                )
                  return;
                let scheduledDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].scheduledDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                let performedDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].performedDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                const is_before_or_equal_date = (date1, date2) => date1 <= date2;

                total.push(assuranceData[key]);
                if (is_before_or_equal_date(scheduledDate, performedDate)) {
                  onTime.push(assuranceData[key]);
                } else {
                  overdue.push(assuranceData[key]);
                }
                const todaysDate = new Date();
                const dueDate = assuranceData[key].checkFrequency.nextCheck.toDate();

                if (dueDate < todaysDate) {
                  count += 1;
                }
              }
            }
          });
        // document.getElementById('totalAssuranceCount').innerText = `${selectedTask}/${totalTasks}`;
        // document.getElementById('completionRate').innerText = '60/76';
      }
    });

  await db
    .collection('compliance')
    .doc('E8Controls')
    .get()
    .then((e8ControlsDoc) => {
      if (e8ControlsDoc.exists) {
        let e8Controls = e8ControlsDoc.data();
        Object.keys(e8Controls)
          .sort()
          .forEach((key) => {
            totalControls++;
            selectedControls++;
          });
      }
    });
  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('product')
    .doc('policyPackages')
    .collection('ISO27001keyDocuments')
    .doc('ismsSoa')
    .get()
    .then((ismsSoa) => {
      if (ismsSoa.exists) {
        let ismsSoaData = ismsSoa.data();
        Object.keys(ismsSoaData)
          .sort()
          .forEach((key) => {
            if (key.startsWith('E8')) {
              if (ismsSoaData[key].controlApplicable === false) {
                selectedControls = selectedControls - 1;
              }
            }
          });
      }
    });

  // document.getElementById('completionRate').innerText = `${selectedControls}/${totalControls}`;

  return {
    total,
    onTime,
    overdue,
    totalTasks,
    selectedTask,
    totalControls,
    selectedControls,
    totalAssuranceCount: {
      selectedTask,
      totalTasks,
    },
    completionRate: {
      selectedControls,
      totalControls,
    },
    onTimePercentage: Math.round((onTime.length / total.length) * 100),
    overduePercentage: Math.round((overdue.length / total.length) * 100),
  };
}

const ActivityRings = (
  selector = 'ActivityRingsChart',
  options = {
    controlsImplementedPercentage: 76,
    tasksOnTimePercentage: 54,
    usersCompletedReading: 65,
  },
) => {
  /**
   * In the chart render event, add icons on top of the circular shapes
   */
  function renderIcons() {
    this.series.forEach((series) => {
      if (!series.icon) {
        series.icon = this.renderer
          .text(`<i class="fa-duotone fa-${series.options.custom.icon}"></i>`, 0, 0, true)
          .attr({
            zIndex: 10,
          })
          .css({
            color: series.options.custom.iconColor,
            fontSize: '0.8em',
          })
          .add(this.series[2].group);
      }
      series.icon.attr({
        x: this.chartWidth / 2 - 15,
        y:
          this.plotHeight / 2.05 -
          series.points[0].shapeArgs.innerR -
          (series.points[0].shapeArgs.r - series.points[0].shapeArgs.innerR) / 2 +
          8,
      });
    });
  }

  // const trackColors = Highcharts.getOptions().colors.map((color) =>
  //   new Highcharts.Color(color).setOpacity(0.3).get(),
  // );
  const trackColors = deiterateColors.map((color) =>
    new Highcharts.Color(color).setOpacity(0.3).get(),
  );

  Highcharts.chart(selector, {
    chart: {
      type: 'solidgauge',
      height: '215',
      width: '315',
      // events: {
      //   render: renderIcons,
      // },
      backgroundColor: 'transparent',
    },

    title: {
      text: null,
      // style: {
      //   fontSize: '24px',
      // },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    menu: {
      enabled: false,
    },

    tooltip: {
      borderWidth: 0,
      backgroundColor: 'white',
      shadow: true,
      // style: {
      //   fontSize: '16px',
      // },
      valueSuffix: '%',
      pointFormat:
        '<center>{series.name}<br>' +
        '<span style="font-size: 1em; color: {point.color}; ' +
        'font-weight: bold">{point.y}</span></center>',
      positioner: function (labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.plotHeight / 2 + 15,
        };
      },
    },

    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          // Track for Conversion
          outerRadius: '112%',
          innerRadius: '88%',
          backgroundColor: trackColors[5],
          borderWidth: 0,
        },
        {
          // Track for Engagement
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: trackColors[4],
          borderWidth: 0,
        },
        {
          // Track for Feedback
          outerRadius: '62%',
          innerRadius: '38%',
          backgroundColor: trackColors[3],
          borderWidth: 0,
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },

    series: [
      {
        name: 'Controls Implemented',
        data: [
          {
            color: deiterateColors[5],
            radius: '112%',
            innerRadius: '88%',
            y: options.controlsImplementedPercentage,
          },
        ],
        custom: {
          icon: 'tower-control',
          iconColor: '#303030',
        },
      },
      {
        name: 'Tasks Completed On-Time',
        data: [
          {
            color: deiterateColors[4],
            radius: '87%',
            innerRadius: '63%',
            y: options.tasksOnTimePercentage,
          },
        ],
        custom: {
          icon: 'list-check',
          iconColor: '#ffffff',
        },
      },
      {
        name: 'Users Completed Reading',
        data: [
          {
            color: deiterateColors[3],
            radius: '62%',
            innerRadius: '38%',
            y: options.usersCompletedReading,
          },
        ],
        custom: {
          icon: 'book-open',
          iconColor: '#303030',
        },
      },
    ],
  });
};

const DumbBell = (
  selector = 'DumbBellChart',
  data = [
    {
      name: 'Objective #1',
      low: moment('10/02/2023', 'DD/MM/YYYY').unix(),
      high: moment('27/04/2024', 'DD/MM/YYYY').unix(),
    },
    {
      name: 'Objective #2',
      low: moment('16/05/2023', 'DD/MM/YYYY').unix(),
      high: moment('10/02/2024', 'DD/MM/YYYY').unix(),
    },
    {
      name: 'Objective #3',
      low: moment('05/04/2024', 'DD/MM/YYYY').unix(),
      high: moment('30/04/2024', 'DD/MM/YYYY').unix(),
    },
  ],
) => {
  Highcharts.chart(selector, {
    chart: {
      type: 'dumbbell',
      inverted: true,
    },

    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    menu: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    title: {
      text: null, // Hide title
    },

    tooltip: {
      shared: true,
      formatter: function () {
        // console.log(moment.unix(1682530493).format('DD/MM/YYYY'));
        return (
          moment.unix(this.points[0].point.low).format('DD/MM/YYYY') +
          ' to ' +
          moment.unix(this.points[0].point.high).format('DD/MM/YYYY')
        );
      },
    },

    xAxis: {
      type: 'category',
    },

    yAxis: {
      title: {
        text: 'Last Review Date - Next Review Date',
      },
      type: 'datetime',
      // labels: {
      //   format: '{value:%d %b}', // e.g. 13 Mar
      // },
      dateTimeLabelFormats: {
        millisecond: '%M:%S',
        second: '%M:%S',
        minute: '%M:%S',
        hour: '%M:%S',
        day: '%M:%S',
        week: '%M:%S',
        month: '%M:%S',
        year: '%M:%S',
      },
      labels: {
        formatter: function () {
          return moment.unix(this.value).format('DD/MM/YYYY');
        },
      },
      // labels: {
      //   formatter: function () {
      //     console.log('This is: ', this)
      //
      //     return Highcharts.dateFormat('%H:%M:%S %Y', this.value);
      //   },
      // },
    },

    series: [
      {
        name: '(Last Review Date) - (Next Review Date)',
        data: data,
      },
    ],
  });
};

const LineChart = (
  selector = 'LineChart',
  options = {
    chart: {
      type: 'spline',
    },
    title: {
      text: null, // Hide title
    },
    menu: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      accessibility: {
        description: 'Months of the year',
      },
    },
    yAxis: {
      title: {
        text: 'Upguard Score',
      },
      labels: {
        format: '{value}',
      },
      tickInterval: 1,
      min: 0,
      max: 950,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function () {
        // If you want to see what is available in the formatter, you can
        // examine the `this` variable.
        //

        return (
          '<b>' + Highcharts.numberFormat(this.y, 0) + '</b><br/>' + 'in year: ' + this.point.name
        );
      },
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1,
        },
      },
    },
    series: [
      {
        name: 'de.iterate',
        marker: {
          symbol: 'square',
        },
        data: [0, 0, 0, 354, 436, 235, 856, 465, 234, 643, 865, 950],
      },
      {
        name: 'Mojo Layers',
        marker: {
          symbol: 'diamond',
        },
        data: [0, 129, 64, 234, 345, 18, 235, 634, 754, 487, 934, 940],
      },
    ],
  },
) => {
  // Data retrieved https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature
  Highcharts.chart(selector, {
    chart: {
      type: 'spline',
    },
    title: {
      text: null, // Hide title
    },
    menu: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      accessibility: {
        description: 'Months of the year',
      },
    },
    yAxis: {
      title: {
        text: 'Upguard Score',
      },
      labels: {
        format: '{value}',
      },
      tickInterval: 1,
      min: 0,
      max: 950,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1,
        },
      },
    },
    series: options.series,
  });
};

function DonutChart(
  selector = 'DonutChart',
  options = {
    chart: {
      height: 225,
      width: 335,
      type: 'variablepie',
    },
    text: `${Math.round((235 / 345) * 100)}%</br> on-time`,
    series: [
      {
        minPointSize: 10,
        innerSize: '80%',
        zMin: 0,
        dataLabels: {
          enabled: false,
        },
        name: 'Essential 8 Assurance Tasks',
        data: [
          {
            name: 'On Time',
            z: 60,
            y: (235 / 345) * 100,
            color: 'rgb(106, 168, 79)',
          },
          {
            name: 'Overdue',
            z: 60,
            y: (45 / 345) * 100,
            color: 'rgb(103, 78, 167)',
          },
        ],
      },
    ],
  },
) {
  window.Highcharts.chart(selector, {
    chart: options.chart,
    exporting: {
      enabled: false,
    },
    tooltip: options.tooltip || {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      enabled: true,
      verticalAlign: 'middle',
      align: 'center',
      text: options.text,
      style: {
        visibility: true,
        align: 'center',
        fontFamily: 'Arial',
        fontSize: '16px',
        color: 'grey',
      },
    },
    series: options.series,
  });
}
window.DonutChart = DonutChart;

/* Dashboard 1.0 Remanence */

function getUsers() {
  const companyRef = db.collection('customers').doc(window.companyID);
  companyRef.get().then((doc) => {
    if (doc.exists) {
      const dbAmountOfUsers = doc.data().users;

      if (dbAmountOfUsers.length != null) {
        document.getElementById('amountOfUsersLbl').textContent = dbAmountOfUsers.length;
        amountOfUsers = dbAmountOfUsers.length;
        numberOfLicenses = doc.data().licensesPurchased;
        numberOfLicensesUsed = doc.data().licensesUsed;
        document.getElementById('numberOfLicenses').textContent =
          `${numberOfLicensesUsed}/${numberOfLicenses}`;
        tenancyName = doc.data().customerFriendlyName;
        document.getElementById('tenancyName').textContent = tenancyName;
        window.readingTimeCompleted = parseInt(doc.data().completedReadMinutes);
        window.assignedReadMinutes = parseInt(doc.data().assignedReadMinutes);
        const readingProgressBar = document.getElementById('readingMinutesProgressBar');
        window.completedPercentage = Math.round(
          (parseInt(readingTimeCompleted) / parseInt(assignedReadMinutes)) * 100,
        );
        document.getElementById('minutesPercentageComplete').textContent =
          `${window.completedPercentage}%`;
        readingProgressBar.setAttribute('aria-valueNow', readingTimeCompleted);
        readingProgressBar.setAttribute('aria-valueMax', assignedReadMinutes);
        readingProgressBar.setAttribute('style', `width: ${completedPercentage}%`);
        document.getElementById('subtitleReading').textContent = 'Company Wide Reading Progress';
        document.getElementById('subtitleQuiz').textContent = 'Company Wide Quiz Progress';
      } else {
        document.getElementById('amountOfUsersLbl').textContent = '0';
        tenancyName = doc.data().customerFriendlyName;
        document.getElementById('tenancyName').textContent = tenancyName;
        window.readingTimeCompleted = parseInt(doc.data().completedReadMinutes);
        window.assignedReadMinutes = parseInt(doc.data().assignedReadMinutes);
        const readingProgressBar = document.getElementById('readingMinutesProgressBar');
        window.completedPercentage = Math.round(
          (parseInt(readingTimeCompleted) / parseInt(assignedReadMinutes)) * 100,
        );
        document.getElementById('minutesPercentageComplete').textContent =
          `${window.completedPercentage}%`;
        readingProgressBar.setAttribute('aria-valueNow', readingTimeCompleted);
        readingProgressBar.setAttribute('aria-valueMax', assignedReadMinutes);
        readingProgressBar.setAttribute('style', `width: ${completedPercentage}%`);
        document.getElementById('subtitleReading').textContent = 'Company Wide Reading Progress';
        document.getElementById('subtitleQuiz').textContent = 'Company Wide Reading Progress';
      }
    } else {
    }
  });
}

function getUsersAll() {
  let currentTimeAssigned = 0;
  let currentTimeCompleted = 0;
  const companyRef = db.collectionGroup('users').where('totalCurrentTime', '!=', '');
  companyRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      document.getElementById('amountOfUsersLbl').textContent = querySnapshot.size;
      currentTimeAssigned += parseInt(doc.data().totalCurrentTime);
      currentTimeCompleted += parseInt(doc.data().currentReadTime);
      const readingProgressBar = document.getElementById('readingMinutesProgressBar');
      window.completedPercentage = Math.round(
        (parseInt(currentTimeCompleted) / parseInt(currentTimeAssigned)) * 100,
      );
      document.getElementById('minutesPercentageComplete').textContent =
        `${window.completedPercentage}%`;
      readingProgressBar.setAttribute('aria-valueNow', currentTimeCompleted);
      readingProgressBar.setAttribute('aria-valueMax', currentTimeAssigned);
      readingProgressBar.setAttribute('style', `width: ${completedPercentage}%`);
      document.getElementById('subtitleReading').textContent = 'App Wide Reading Progress';
    });
  });
}

function getQuizStatus() {
  let completedCount;
  let incompleteCount;
  const completedPolicy = db
    .collectionGroup('assignedPolicies')
    .where('completed', '==', 'true')
    .where('assignedBy', '==', window.companyID);
  completedPolicy.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if ((doc.data().assignedBy = window.companyID)) {
        completedCount = querySnapshot.size;
        const quizProgressBar = document.getElementById('quizProgressBar');
        quizProgressBar.setAttribute('aria-valueNow', completedCount);
      }
    });
  });
  const incompletePolicy = db
    .collectionGroup('assignedPolicies')
    .where('completed', '==', 'false')
    .where('assignedBy', '==', window.companyID);
  incompletePolicy.get().then((querySnapshot1) => {
    querySnapshot1.forEach((doc) => {
      if (doc.data().assignedBy == window.companyID) {
        incompleteCount = querySnapshot1.size - 1;
        const quizProgressBar = document.getElementById('quizProgressBar');
        quizProgressBar.setAttribute('aria-valueMax', incompleteCount);
        const quizProgressLbl = document.getElementById('quizPercentageComplete');
        const quizCompletedPercentage = (completedCount / incompleteCount) * 100;
        quizProgressLbl.textContent = `${Math.round(quizCompletedPercentage)}%`;
        quizProgressBar.setAttribute('style', `width: ${quizCompletedPercentage}%`);
      }
    });
  });
}

function getQuizStatusAll() {
  let completedCount1 = 0;
  let incompleteCount1 = 0;
  const completedPolicy = db.collectionGroup('assignedPolicies').where('completed', '==', 'true');
  completedPolicy.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      completedCount1 = querySnapshot.size;
      const quizProgressBar = document.getElementById('quizProgressBar');
      quizProgressBar.setAttribute('aria-valueNow', completedCount1);
      document.getElementById('subtitleQuiz').textContent = 'App Wide Quiz Progress';
    });
  });
  const incompletePolicy = db.collectionGroup('assignedPolicies').where('completed', '==', 'false');
  incompletePolicy.get().then((querySnapshot1) => {
    querySnapshot1.forEach((doc) => {
      incompleteCount1 = querySnapshot1.size - 1;

      const quizProgressBar = document.getElementById('quizProgressBar');
      quizProgressBar.setAttribute('aria-valueMax', incompleteCount1);
      const quizProgressLbl = document.getElementById('quizPercentageComplete');
      const quizCompletedPercentage1 = (completedCount1 / incompleteCount1) * 100;
      quizProgressLbl.textContent = `${Math.round(quizCompletedPercentage1)}%`;
      quizProgressBar.setAttribute('style', `width: ${quizCompletedPercentage1}%`);
    });
  });
}

function appWideRoles() {
  let adminRoles = 0;
  let ownerRoles = 0;
  let userRoles = 0;
  let totalUsers = 0;
  const adminRoleRef = db.collection('users');
  adminRoleRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().roles.admin == true) {
        adminRoles += 1;
        totalUsers += 1;
      }
    });
  });
  const ownerRoleRef = db.collection('users');
  ownerRoleRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().roles.owner == true) {
        ownerRoles += 1;
        totalUsers += 1;
      }
    });
  });
  const userRoleRef = db.collection('users');
  userRoleRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().roles.user == true) {
        userRoles += 1;
        totalUsers += 1;
      }
    });
  });
}

function companyWideRoles() {
  let adminRoles = 0;
  let ownerRoles = 0;
  let userRoles = 0;
  const adminRoleRef = db
    .collection('users')
    .where('organisations', 'array-contains', window.companyID);
  adminRoleRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().roles.admin == true) {
        adminRoles += 1;
      }
    });
  });
  const ownerRoleRef = db
    .collection('users')
    .where('organisations', 'array-contains', window.companyID);
  ownerRoleRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().roles.owner == true) {
        ownerRoles += 1;
      }
    });
  });
  const userRoleRef = db
    .collection('users')
    .where('organisations', 'array-contains', window.companyID);
  userRoleRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data().roles.user == true) {
        userRoles += 1;
      }
    });
  });
}

// async function getallData() {
//   db.collection('customers')
//     .doc(window.companyID)
//     .get()
//     .then(async (baseTenancyDoc) => {
//       if (baseTenancyDoc.exists) {
//         let baseTenancyData = await baseTenancyDoc.data();
//         let customerPlan = baseTenancyData?.customerPlan;

//         if (customerPlan === undefined) {
//           await getAllUsers();
//           await getAllPolicies();
//           await getAllCompliance();
//           await getAllIsmsRoles();
//           uploadData('overdueTasks');
//         } else {
//           await getE8AssuranceData();
//           await getPrivacyActAssuranceData();
//           await getSupplierData(false);
//           await getDataPrivacyData();
//           db.collection('customers')
//             .doc(window.companyID)
//             .collection('compliance')
//             .doc('privacyRequestsTracker')
//             .get()
//             .then(async (prDoc) => {
//               if (prDoc.exists) {
//                 let privacyRequestData = await getDataPrivacyRequests();

//                 document.getElementById('dpasOpen').innerText =
//                   privacyRequestData?.open?.count ?? 0;
//                 document.getElementById('dpasActive').innerText =
//                   privacyRequestData?.active?.count ?? 0;
//                 document.getElementById('dpasComplete').innerText =
//                   privacyRequestData?.closed?.count ?? 0;

//                 let currentMonth = [];
//                 let currentYear = [];
//                 let currentdate = new Date();
//                 let cur_month = currentdate.getMonth();
//                 let cur_year = currentdate.getFullYear();

//                 await privacyRequestData.open.requests.forEach((request, index) => {
//                   if (
//                     cur_month ===
//                     privacyRequestData.open.requests[index].requestDate.toDate().getMonth()
//                   ) {
//                     currentMonth.push(request);
//                   }

//                   if (
//                     cur_year ===
//                     privacyRequestData.open.requests[index].requestDate.toDate().getFullYear()
//                   ) {
//                     currentYear.push(request);
//                   }
//                 });

//                 await privacyRequestData.active.requests.forEach((request, index) => {
//                   if (
//                     cur_month ===
//                     privacyRequestData.active.requests[index].requestDate.toDate().getMonth()
//                   ) {
//                     currentMonth.push(request);
//                   }

//                   if (
//                     cur_year ===
//                     privacyRequestData.active.requests[index].requestDate.toDate().getFullYear()
//                   ) {
//                     currentYear.push(request);
//                   }
//                 });

//                 await privacyRequestData.closed.requests.forEach((request, index) => {
//                   if (
//                     cur_month ===
//                     privacyRequestData.closed.requests[index].requestDate.toDate().getMonth()
//                   ) {
//                     currentMonth.push(request);
//                   }

//                   if (
//                     cur_year ===
//                     privacyRequestData.closed.requests[index].requestDate.toDate().getFullYear()
//                   ) {
//                     currentYear.push(request);
//                   }
//                 });

//                 document.getElementById('thisMonthCount').innerText =
//                   currentMonth.length.toString();
//                 document.getElementById('thisYearCount').innerText = currentYear.length.toString();
//               }
//             });
//         }
//       }
//     });
// }

async function getDataPrivacyRequests() {
  let dataPrivacyTracker = {
    open: {
      count: 0,
      requests: [],
    },
    closed: {
      count: 0,
      requests: [],
    },
    active: {
      count: 0,
      requests: [],
    },
  };

  let requestDocRef = db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('privacyRequestsTracker');
  let dataPrivacyTrackerSnapshot = await requestDocRef.get();

  if (dataPrivacyTrackerSnapshot.exists) {
    let docData = dataPrivacyTrackerSnapshot.data();

    for (let i = 0; i < Object.keys(docData).length; i++) {
      let key = Object.keys(docData)[i];
      let request = docData[key];
      let requestStatus = request.status.toLowerCase();

      if (requestStatus === 'open') {
        dataPrivacyTracker.open.count++;
        request.requestID = key;
        request.isCompleted = false;
        dataPrivacyTracker.open.requests.push(request);
      } else if (requestStatus === 'closed') {
        dataPrivacyTracker.closed.count++;
        request.requestID = key;
        request.isCompleted = false;
        dataPrivacyTracker.closed.requests.push(request);
      } else if (requestStatus === 'active') {
        dataPrivacyTracker.active.count++;
        request.requestID = key;
        request.isCompleted = false;
        dataPrivacyTracker.active.requests.push(request);
      } else if (requestStatus === 'completed') {
        dataPrivacyTracker.closed.count++;
        request.requestID = key;
        request.isCompleted = true;
        dataPrivacyTracker.closed.requests.push(request);
      } else {
      }
    }

    let publicDoc = db.collection('privacyRequestTracker').doc(window.companyID);

    let publicDocSnap = await publicDoc.get();

    if (publicDocSnap.exists) {
      let docData = publicDocSnap.data();

      for (let i = 0; i < Object.keys(docData).length; i++) {
        let key = Object.keys(docData)[i];
        let request = docData[key];
        let requestStatus = request.status.toLowerCase();

        if (requestStatus === 'open') {
          dataPrivacyTracker.open.count++;
          request.requestID = key;
          request.isCompleted = false;
          dataPrivacyTracker.open.requests.push(request);
        } else if (requestStatus === 'closed') {
          dataPrivacyTracker.closed.count++;
          request.requestID = key;
          request.isCompleted = false;
          dataPrivacyTracker.closed.requests.push(request);
        } else if (requestStatus === 'active') {
          dataPrivacyTracker.active.count++;
          request.requestID = key;
          request.isCompleted = false;
          dataPrivacyTracker.active.requests.push(request);
        } else if (requestStatus === 'completed') {
          dataPrivacyTracker.closed.count++;
          request.requestID = key;
          request.isCompleted = true;
          dataPrivacyTracker.closed.requests.push(request);
        } else {
        }
      }

      return dataPrivacyTracker;
    } else {
      console.warn('[Data Privacy Tracker] No such document!');

      return dataPrivacyTracker;
    }
  } else {
    console.warn('[Data Privacy Tracker] No such document! Checking Public Collection');
    let publicDoc = db.collection('privacyRequestTracker').doc(window.companyID);

    let publicDocSnap = await publicDoc.get();

    if (publicDocSnap.exists) {
      let docData = publicDocSnap.data();

      for (let i = 0; i < Object.keys(docData).length; i++) {
        let key = Object.keys(docData)[i];
        let request = docData[key];
        let requestStatus = request.status.toLowerCase();

        if (requestStatus === 'open') {
          dataPrivacyTracker.open.count++;
          request.requestID = key;
          request.isCompleted = false;
          dataPrivacyTracker.open.requests.push(request);
        } else if (requestStatus === 'closed') {
          dataPrivacyTracker.closed.count++;
          request.requestID = key;
          request.isCompleted = false;
          dataPrivacyTracker.closed.requests.push(request);
        } else if (requestStatus === 'active') {
          dataPrivacyTracker.active.count++;
          request.requestID = key;
          request.isCompleted = false;
          dataPrivacyTracker.active.requests.push(request);
        } else if (requestStatus === 'completed') {
          dataPrivacyTracker.closed.count++;
          request.requestID = key;
          request.isCompleted = true;
          dataPrivacyTracker.closed.requests.push(request);
        } else {
        }
      }

      return dataPrivacyTracker;
    } else {
      console.warn('[Data Privacy Tracker] No such document!');

      return dataPrivacyTracker;
    }
  }
}

window.getDataPrivacyRequests = getDataPrivacyRequests;

async function getE8AssuranceData() {
  let total = [];
  let onTime = [];
  let overdue = [];
  let totalTasks = 0;
  let selectedTask = 0;
  let totalControls = 0;
  let selectedControls = 0;

  db.collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()
    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        let count = 0;
        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (assuranceData[key].checkDetail.framework[0].frameName === 'Essential 8') {
              totalTasks++;
            }
            if (
              assuranceData[key].hasOwnProperty('checkDetail') &&
              assuranceData[key].checkDetail.hasOwnProperty('active') &&
              assuranceData[key].checkDetail.active === 'true'
            ) {
              if (assuranceData[key].checkDetail.framework[0].frameName === 'Essential 8') {
                selectedTask++;
                let numberPreviousChecks = assuranceData[key].checkHistory.numberPreviousChecks;
                if (
                  assuranceData[key].checkHistory[numberPreviousChecks].scheduledDate === '' ||
                  assuranceData[key].checkHistory[numberPreviousChecks].performedDate === ''
                )
                  return;

                let scheduledDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].scheduledDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                let performedDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].performedDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                const is_before_or_equal_date = (date1, date2) => date1 <= date2;

                total.push(assuranceData[key]);
                if (is_before_or_equal_date(scheduledDate, performedDate)) {
                  onTime.push(assuranceData[key]);
                } else {
                  overdue.push(assuranceData[key]);
                }
                const todaysDate = new Date();
                const dueDate = assuranceData[key].checkFrequency.nextCheck.toDate();

                if (dueDate < todaysDate) {
                  count += 1;
                  let overdueTasksDiv = document.getElementById('overDueTasksList');
                  let overdueTaskBlock = document.createElement('div');
                  if (count === 1) {
                    overdueTaskBlock.innerHTML = `${
                      assuranceData[key].checkDetail.check
                    }</br></br><b>Due on:</b> ${dueDate.toLocaleString().split(', ')[0]}`;
                    overdueTaskBlock.classList.add('blueDiv');
                  } else if (count % 2 === 0) {
                    overdueTaskBlock.innerHTML = `${
                      assuranceData[key].checkDetail.check
                    }</br></br><b>Due on:</b> ${dueDate.toLocaleString().split(', ')[0]}`;
                    overdueTaskBlock.classList.add('orangeDiv');
                  } else {
                    overdueTaskBlock.innerHTML = `${
                      assuranceData[key].checkDetail.check
                    }</br></br><b>Due on:</b> ${dueDate.toLocaleString().split(', ')[0]}`;
                    overdueTaskBlock.classList.add('blueDiv');
                  }
                  overdueTaskBlock.style.maxWidth = '100%';
                  overdueTaskBlock.style.width = 'calc(100% - 24px)';
                  overdueTaskBlock.style.cursor = 'pointer';
                  overdueTasksDiv.appendChild(overdueTaskBlock);
                  overdueTaskBlock.addEventListener('click', () => {
                    window.location.href = 'assurance-actions.html?assID=' + key;
                  });
                }
              }
            }
          });
        window.Highcharts.chart('e8AssuranceTaskChartDiv', {
          chart: {
            height: '225',
            type: 'variablepie',
          },
          exporting: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          title: {
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            text: `${Math.round((onTime.length / total.length) * 100)}%</br> on-time`,
            style: {
              visibility: true,
              align: 'center',
              fontFamily: 'Arial',
              fontSize: '16px',
              color: 'grey',
            },
          },
          series: [
            {
              minPointSize: 10,
              innerSize: '80%',
              zMin: 0,
              dataLabels: {
                enabled: false,
              },
              name: 'Essential 8 Assurance Tasks',
              data: [
                {
                  name: 'On Time',
                  z: 60,
                  y: (onTime.length / total.length) * 100,
                  color: 'rgb(106, 168, 79)',
                },
                {
                  name: 'Overdue',
                  z: 60,
                  y: (overdue.length / total.length) * 100,
                  color: 'rgb(103, 78, 167)',
                },
              ],
            },
          ],
        });
        document.getElementById('totalAssuranceCount').innerText = `${selectedTask}/${totalTasks}`;
        document.getElementById('completionRate').innerText = '60/76';
      }
    });

  await db
    .collection('compliance')
    .doc('E8Controls')
    .get()
    .then((e8ControlsDoc) => {
      if (e8ControlsDoc.exists) {
        let e8Controls = e8ControlsDoc.data();
        Object.keys(e8Controls)
          .sort()
          .forEach((key) => {
            totalControls++;
            selectedControls++;
          });
      }
    });
  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('product')
    .doc('policyPackages')
    .collection('ISO27001keyDocuments')
    .doc('ismsSoa')
    .get()
    .then((ismsSoa) => {
      if (ismsSoa.exists) {
        let ismsSoaData = ismsSoa.data();
        Object.keys(ismsSoaData)
          .sort()
          .forEach((key) => {
            if (key.startsWith('E8')) {
              if (ismsSoaData[key].controlApplicable === false) {
                selectedControls = selectedControls - 1;
              }
            }
          });
      }
    });

  document.getElementById('completionRate').innerText = `${selectedControls}/${totalControls}`;
}

async function getPrivacyActAssuranceData() {
  let total = [];
  let onTime = [];
  let overdue = [];
  let totalTasks = 0;
  let selectedTask = 0;
  let totalControls = 0;
  let selectedControls = 0;
  db.collection('customers')
    .doc(window.companyID)
    .collection('compliance')
    .doc('assurance')
    .get()
    .then((assuranceDoc) => {
      if (assuranceDoc.exists) {
        let assuranceData = assuranceDoc.data();
        Object.keys(assuranceData)
          .sort()
          .forEach((key) => {
            if (assuranceData[key].checkDetail.framework[0].frameName === 'Privacy Act') {
              totalTasks++;
            }
            if (
              assuranceData[key].hasOwnProperty('checkDetail') &&
              assuranceData[key].checkDetail.hasOwnProperty('active') &&
              assuranceData[key].checkDetail.active === 'true'
            ) {
              if (assuranceData[key].checkDetail.framework[0].frameName === 'Privacy Act') {
                selectedTask++;
                let numberPreviousChecks = assuranceData[key].checkHistory.numberPreviousChecks;
                if (
                  assuranceData[key].checkHistory[numberPreviousChecks].scheduledDate === '' ||
                  assuranceData[key].checkHistory[numberPreviousChecks].performedDate === ''
                )
                  return;

                let scheduledDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].scheduledDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                let performedDate = assuranceData[key].checkHistory[
                  numberPreviousChecks
                ].performedDate
                  .toDate()
                  .setHours(0, 0, 0, 0);
                const is_before_or_equal_date = (date1, date2) => date1 <= date2;

                total.push(assuranceData[key]);
                if (is_before_or_equal_date(scheduledDate, performedDate)) {
                  onTime.push(assuranceData[key]);
                } else {
                  overdue.push(assuranceData[key]);
                }
              }
            }
          });
        window.Highcharts.chart('privacyActAssuranceTaskChartDiv', {
          chart: {
            height: '225',
            type: 'variablepie',
          },
          exporting: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          title: {
            enabled: true,
            verticalAlign: 'middle',
            align: 'center',
            text: `100%</br> on-time`,
            style: {
              visibility: true,
              align: 'center',
              fontFamily: 'Arial',
              fontSize: '16px',
              color: 'grey',
            },
          },
          series: [
            {
              minPointSize: 10,
              innerSize: '80%',
              zMin: 0,
              dataLabels: {
                enabled: false,
              },
              name: 'Privacy Act Assurance Tasks',
              data: [
                {
                  name: 'On Time',
                  z: 60,
                  y: 100,
                  color: 'rgb(11, 83, 148)',
                },
                {
                  name: 'Overdue',
                  z: 60,
                  y: 0,
                  color: 'rgb(230, 145, 56)',
                },
              ],
            },
          ],
        });
        document.getElementById('privacyActTotalAssuranceCount').innerText =
          `${selectedTask}/${totalTasks}`;
        document.getElementById('privacyActCompletionRate').innerText = '28/30';
      }
    });

  await db
    .collection('compliance')
    .doc('paControls')
    .get()
    .then((e8ControlsDoc) => {
      if (e8ControlsDoc.exists) {
        let e8Controls = e8ControlsDoc.data();
        Object.keys(e8Controls)
          .sort()
          .forEach((key) => {
            totalControls++;
            selectedControls++;
          });
      }
    });
  await db
    .collection('customers')
    .doc(window.companyID)
    .collection('product')
    .doc('policyPackages')
    .collection('ISO27001keyDocuments')
    .doc('ismsSoa')
    .get()
    .then((ismsSoa) => {
      if (ismsSoa.exists) {
        let ismsSoaData = ismsSoa.data();
        Object.keys(ismsSoaData)
          .sort()
          .forEach((key) => {
            if (key.startsWith('PA')) {
              if (ismsSoaData[key].controlApplicable === false) {
                selectedControls = selectedControls - 1;
              }
            }
          });
      }
    });

  document.getElementById('privacyActCompletionRate').innerText =
    `${selectedControls}/${totalControls}`;
}

window.getSupplierData = async (checked) => {
  let data = [];
  let sanctionedCountriesData = [];
  let europeanData = [];
  let africaData = [];
  let northAmericaData = [];
  let southAmericaData = [];
  let asiaData = [];
  let middleEastData = [];
  let oceanPacificData = [];
  let dataStorageLocations = [];
  let dataFlowArray = [];
  const pastelColors = [
    '#FDCB9E',
    '#BEE3DB',
    '#F5CAC3',
    '#D7E5A1',
    '#F3E1A5',
    '#C7CEEA',
    '#E2A9A1',
  ];

  let colorIndex = 0;
  const colorMap = {};
  let dataTypes = [];
  let count = 0;
  let dataTypesCount = 0;
  let europeanCountries = [
    'Albania',
    'Andorra',
    'Armenia',
    'Austria',
    'Azerbaijan',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Georgia',
    'Germany',
    'Greece',
    'Iceland',
    'Ireland',
    'Italy',
    'Kazakhstan',
    'Kosovo',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Malta',
    'Moldova',
    'Monaco',
    'Montenegro',
    'Netherlands',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'Russia',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Ukraine',
    'United Kingdom',
    'Vatican City',
  ];
  let africaCountries = [
    'Algeria',
    'Angola',
    'Benin',
    'Botswana',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'Comoros',
    'Democratic Republic of the Congo',
    'Republic of the Congo',
    "Cote d'Ivoire",
    'Djibouti',
    'Equatorial Guinea',
    'Eritrea',
    'Ethiopia',
    'Gabon',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea Bissau',
    'Kenya',
    'Lesotho',
    'Liberia',
    'Libya',
    'Madagascar',
    'Malawi',
    'Mali',
    'Mauritania',
    'Mauritius',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Niger',
    'Nigeria',
    'Rwanda',
    'Sao Tome and Principe',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Sudan',
    'Swaziland',
    'Tanzania',
    'Togo',
    'Tunisia',
    'Uganda',
    'Zambia',
    'Zimbabwe',
  ];
  let northAmericaCountries = [
    'Antigua and Barbuda',
    'Bahamas',
    'Barbados',
    'Belize',
    'Canada',
    'Costa Rica',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'El Salvador',
    'Grenada',
    'Guatemala',
    'Haiti',
    'Honduras',
    'Jamaica',
    'Mexico',
    'Nicaragua',
    'Panama',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Trinidad and Tobago',
    'United States of America',
  ];
  let southAmericaCountries = [
    'Argentina',
    'Bolivia',
    'Brazil',
    'Chile',
    'Colombia',
    'Ecuador',
    'Guyana',
    'Paraguay',
    'Peru',
    'Suriname',
    'Uruguay',
    'Venezuela',
  ];
  let asiaCountries = [
    'Armenia',
    'Azerbaijan',
    'Bangladesh',
    'Bhutan',
    'Brunei',
    'Cambodia',
    'China',
    'Georgia',
    'India',
    'Indonesia',
    'Japan',
    'Kazakhstan',
    'Kyrgyzstan',
    'Laos',
    'Malaysia',
    'Maldives',
    'Mongolia',
    'Myanmar',
    'Nepal',
    'North Korea',
    'Pakistan',
    'Philippines',
    'Russia',
    'Singapore',
    'South Korea',
    'Sri Lanka',
    'Taiwan',
    'Tajikistan',
    'Thailand',
    'Timor Leste',
    'Turkmenistan',
    'Uzbekistan',
    'Vietnam',
  ];
  let middleEastCountries = [
    'Bahrain',
    'Cyprus',
    'Egypt',
    'Iran',
    'Iraq',
    'Israel',
    'Jordan',
    'Kuwait',
    'Lebanon',
    'Oman',
    'Palestine',
    'Qatar',
    'Saudi Arabia',
    'Syria',
    'Turkey',
    'United Arab Emirates',
    'Yemen',
  ];
  let oceanPacificCountries = [
    'Australia',
    'Federated Islands of Micronesia',
    'Fiji',
    'French Polynesia',
    'Guam',
    'Kiribati',
    'Marshall Islands',
    'Nauru',
    'New Zealand',
    'Paulau',
    'Papua New Guinea',
    'Samoa',
    'Solomon Islands',
    'Tonga',
    'Tuvala',
    'Vanuata',
  ];
  db.collection('compliance')
    .doc('sanctionedCountries')
    .get()
    .then((doc) => {
      if (doc.exists) {
        let sanctionedCountries = doc.data().sanctionedCountries;
        db.collection('customers')
          .doc(window.companyID)
          .collection('compliance')
          .doc('asset')
          .get()
          .then((supplierDoc) => {
            if (supplierDoc.exists) {
              console.log('Supplier Data Exists');
              let suppliersMap = supplierDoc.data().register.assets;
              Object.keys(suppliersMap)
                .sort()
                .forEach((key) => {
                  console.log(suppliersMap[key]);
                  if (
                    (window.customerPlanFriendly === 'iso' &&
                      (suppliersMap[key].hasOwnProperty('assetIDstatus') ||
                        suppliersMap[key].hasOwnProperty('assetIDStatus')) &&
                      (suppliersMap[key].assetIDstatus === 'active' ||
                        suppliersMap[key].assetIDStatus === 'active')) ||
                    window.customerPlanFriendly === 'starter'
                  ) {
                    if (
                      suppliersMap[key].hasOwnProperty('dataStorageLocations') &&
                      suppliersMap[key].hasOwnProperty('dataTypesShared')
                    ) {
                      suppliersMap[key].dataStorageLocations.forEach((location) => {
                        if (
                          location === 'Europe' ||
                          location === 'EU' ||
                          location === 'European Union'
                        ) {
                          dataStorageLocations.push('Germany');
                        } else if (
                          location === 'North America' ||
                          location === 'United States' ||
                          location === 'USA' ||
                          location === 'US' ||
                          location === 'America'
                        ) {
                          dataStorageLocations.push('United States of America');
                        } else if (
                          location === 'Brisbane' ||
                          location === 'Sydney' ||
                          location === 'Melbourne' ||
                          location === 'Adelaide' ||
                          location === 'Perth' ||
                          location === 'Hobart' ||
                          location === 'Darwin'
                        ) {
                          dataStorageLocations.push('Australia');
                        } else {
                          dataStorageLocations.push(location);
                        }
                      });
                      suppliersMap[key].dataTypesShared.forEach((dataType) => {
                        dataTypes.push(dataType);
                      });
                      suppliersMap[key].dataStorageLocations.forEach((location) => {
                        suppliersMap[key].dataTypesShared.forEach((dataType) => {
                          const flowKey = `${dataType}-${suppliersMap[key].assetDesc}-${location}`;
                          if (!colorMap[flowKey]) {
                            colorMap[flowKey] =
                              deiterateColors[colorIndex % deiterateColors.length];
                            colorIndex++;
                          }
                          const flowColor = colorMap[flowKey];
                          dataFlowArray.push({
                            from: dataType,
                            to: suppliersMap[key].assetDesc,
                            weight: 1,
                          });
                          dataFlowArray.push({
                            from: suppliersMap[key].assetDesc,
                            to: location,
                            weight: 1,
                          });
                        });
                      });
                    }
                  }
                });

              const dataStorageCounts = {};
              dataStorageLocations.forEach(function (x) {
                dataStorageCounts[x] = (dataStorageCounts[x] || 0) + 1;
              });

              dataStorageCounts[Symbol.iterator] = function* () {
                yield* [...this.entries()].sort((a, b) => a[1] - b[1]);
              };
              const dataSharedCounts = {};
              dataTypes.forEach(function (x) {
                dataSharedCounts[x] = (dataSharedCounts[x] || 0) + 1;
              });

              dataSharedCounts[Symbol.iterator] = function* () {
                yield* [...this.entries()].sort((a, b) => a[1] - b[1]);
              };
              Object.keys(dataStorageCounts)
                .sort()
                .forEach((x) => {
                  if (sanctionedCountries.includes(x)) {
                    if (!sanctionedCountriesData.includes(x)) {
                      sanctionedCountriesData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (europeanCountries.includes(x)) {
                    if (!europeanData.includes(x)) {
                      europeanData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (africaCountries.includes(x)) {
                    if (!africaData.includes(x)) {
                      africaData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (northAmericaCountries.includes(x)) {
                    if (!northAmericaData.includes(x)) {
                      northAmericaData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (southAmericaCountries.includes(x)) {
                    if (!southAmericaData.includes(x)) {
                      southAmericaData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (asiaCountries.includes(x)) {
                    if (!asiaData.includes(x)) {
                      asiaData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (middleEastCountries.includes(x)) {
                    if (!middleEastData.includes(x)) {
                      middleEastData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else if (oceanPacificCountries.includes(x)) {
                    if (!oceanPacificData.includes(x)) {
                      oceanPacificData.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  } else {
                    if (!data.includes(x)) {
                      data.push({
                        name: x,
                        countryName: x,
                        z: dataStorageCounts[x],
                      });
                    }
                  }
                });
              // console.log(mode(dataStorageLocations));
              console.log('Date Flow Array', dataFlowArray);
              let mapData = Highcharts.geojson(Highcharts.maps['custom/world']);
              if (checked) {
                document.getElementById('dataFlowDiagram').innerHTML = '';
                Highcharts.chart('dataFlowDiagram', {
                  chart: {
                    height: 280,
                    width: 765,
                  },
                  title: false,
                  credits: false,
                  exporting: {
                    enabled: false,
                  },
                  plotOptions: {
                    sankey: {
                      nodeWidth: 20,
                    },
                  },
                  series: [
                    {
                      keys: ['from', 'to', 'weight'],
                      data: dataFlowArray,
                      type: 'sankey',
                      name: 'Data Flow Diagram',
                      colorByPoint: true,
                      colors: pastelColors,
                    },
                  ],
                });
              } else {
                // let mostCommonCountriesDiv = document.getElementById('mostCommonCountriesDiv');
                // mostCommonCountriesDiv.innerHTML = '';
                // let commonCountriesHeader = document.createElement('h4');
                // commonCountriesHeader.innerText = 'Top 5 Data Storage Locations:';
                // mostCommonCountriesDiv.appendChild(commonCountriesHeader);
                // Object.keys(dataStorageCounts)
                //   .sort()
                //   .forEach((x) => {
                //     count += 1;
                //     let countryDiv = document.createElement('div');
                //     if (count <= 5) {
                //       if (count === 1) {
                //         countryDiv.innerHTML = `${x}`;
                //         countryDiv.classList.add('blueDiv');
                //         countryDiv.style.color = deiterateColors[5];
                //         // countryDiv.style.marginLeft = '0';
                //         mostCommonCountriesDiv.appendChild(countryDiv);
                //       } else if (count % 2 === 0) {
                //         countryDiv.innerHTML = `${x}`;
                //         countryDiv.classList.add('orangeDiv');
                //         countryDiv.style.color = deiterateColors[1];
                //         mostCommonCountriesDiv.appendChild(countryDiv);
                //       } else {
                //         countryDiv.innerHTML = `${x}`;
                //         countryDiv.classList.add('blueDiv');
                //         countryDiv.style.color = deiterateColors[5];
                //         mostCommonCountriesDiv.appendChild(countryDiv);
                //       }
                //     }
                //   });
                document.getElementById('suppliersDataMap').innerHTML = '';
                Highcharts.mapChart('suppliersDataMap', {
                  chart: {
                    height: 280,
                    width: 765,
                    borderWidth: 0,
                  },
                  title: false,
                  mapNavigation: {
                    enabled: false,
                  },
                  legend: {
                    enabled: false,
                  },
                  credits: false,
                  exporting: {
                    enabled: false,
                  },
                  buttonOptions: {
                    align: 'right',
                    enabled: true,
                  },
                  series: [
                    {
                      name: 'Countries',
                      mapData: mapData,
                      color: '#E0E0E0',
                      enableMouseTracking: true,
                      enableButtons: true,
                      showInLegend: false,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[6],
                      name: 'North America',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: northAmericaData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[0],
                      name: 'South America',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: southAmericaData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[2],
                      name: 'Europe',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: europeanData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[7],
                      name: 'Asia',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: asiaData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[4],
                      name: 'Middle East',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: middleEastData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[5],
                      name: 'Ocean Pacific',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: oceanPacificData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[3],
                      name: 'Sanctioned Countries',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: sanctionedCountriesData,
                    },
                    {
                      type: 'mapbubble',
                      color: deiterateColors[1],
                      name: 'Other',
                      mapData: mapData,
                      joinBy: ['name', 'countryName'],
                      data: data,
                    },
                  ],
                });
              }
            }
          });
      }
    });
};

window.getSupplierData = getSupplierData;

/*document.getElementById('mapSwitch').addEventListener('change', () => {
  if (document.querySelector('#mapSwitch').checked) {
    getSupplierData(true);
  } else {
    getSupplierData(false);
  }
});*/

// document.getElementById('mapSwitch').addEventListener('click', (el) => {
//   if (document.querySelector('#mapSwitch').checked) {
//     document.querySelector('#mapSwitch').checked = false;
//   } else {
//     document.querySelector('#mapSwitch').checked = true;
//   }
// });

async function getDataPrivacyData() {}

function mode(array) {
  if (array.length === 0) return null;
  let modeMap = {};
  let maxEl = array[0],
    maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    let el = array[i];
    if (modeMap[el] == null) {
      modeMap[el] = 1;
    } else {
      modeMap[el]++;
    }
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

function getAllUsers() {
  const usersdb = db.collection('customers').doc(window.companyID);
  usersdb.get().then(async (res) => {
    await res;

    for (const u in res.data().customerOrg.users) {
      if (res.data().customerOrg.users[u].ismsRole != '') {
        users.push(res.data().customerOrg.users[u]);
      }
    } // users = res.data().customerOrg.users;

    const usersCard = document.querySelector('.users');

    for (const i in users) {
      usersCard.innerHTML = Object.keys(users).length;
    }
  });
}

function getAllPolicies() {
  const policiesdb = db
    .collection('customers')
    .doc(window.companyID)
    .collection('product/policyPackages/policies');
  policiesdb.get().then(async (res) => {
    await res;
    res.docs.forEach((e) => {
      policies.push(e.data());
    });
    const policiesCard = document.querySelector('.activePolicies');
    policiesCard.innerHTML = policies.length;
  });
}

function getAllCompliance() {
  const assuranceTasksdb = db
    .collection('customers')
    .doc(window.companyID)
    .collection('compliance');
  assuranceTasksdb.get().then(async (res) => {
    await res;
    let assetDoc = res.docs.filter((x) => x.id === 'asset');
    let assuranceDoc = res.docs.filter((x) => x.id === 'assurance');
    let riskDoc = res.docs.filter((x) => x.id === 'risk');
    const assetsdata = assetDoc[0].data();
    const assuranceTasksdata = assuranceDoc[0].data();
    const risksdata = riskDoc[0].data();

    for (const e in assetsdata.register.assets) {
      if (!assetsdata.register.assets[e].assetIDstatus) {
        assets.push(assetsdata.register.assets[e]);
      }

      if (assetsdata.register.assets[e].assetCat === 'Outsourced Services') {
        outsourcedServices.push(assetsdata[e]);
      }
    }

    for (const e in assuranceTasksdata) {
      assuranceTasks.push(assuranceTasksdata[e]);
      const todaysDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      const { numberPreviousChecks } = assuranceTasksdata[e].checkHistory;
      const dueDate = assuranceTasksdata[e].checkFrequency.nextCheck.toDate();

      if (dueDate < todaysDate) {
        overdueTasks.push(assuranceTasksdata[e]);
      }
    }

    for (const e in risksdata.register.risks) {
      if (risksdata.register.risks[e].riskIDstatus == 'active') {
        risks.push(risksdata.register.risks[e]);
      }

      if (
        risksdata.register.risks[e].treatRequired === 'Yes' &&
        risksdata.register.risks[e].riskIDstatus == 'active'
      ) {
        treatmentPlans.push(risksdata.register.risks[e]);
      }
    }

    const assetsCard = document.querySelector('.assets');
    const assuranceTasksCard = document.querySelector('.assuranceTasks');
    const risksCard = document.querySelector('.risks');
    const overdueTasksCard = document.querySelector('.overdueTasks');
    const outsourcedServicesCard = document.querySelector('.outsourcedServices');
    const treatmentPlansCard = document.querySelector('.treatmentPlans');
    assetsCard.innerHTML = assets.length;
    assuranceTasksCard.innerHTML = assuranceTasks.length;
    risksCard.innerHTML = risks.length;
    overdueTasksCard.innerHTML = overdueTasks.length;
    outsourcedServicesCard.innerHTML = outsourcedServices.length;
    treatmentPlansCard.innerHTML = treatmentPlans.length;
  });
}

// window.uploadData = function (e) {
//   const iframe = document.querySelector('#myFrame');
//   const load = document.querySelector('.load');
//   iframe.style.display = 'none';
//   iframe.addEventListener('contextmenu', (event) => event.preventDefault());
//   load.style.display = 'flex';

//   if (e === 'users') {
//     document.querySelectorAll('.card_item').forEach((card) => {
//       card.classList.remove('card_item_active');
//     });
//     const currentCard = document.querySelector('.usersCard');
//     currentCard.classList.add('card_item_active');
//     iframe.src = `${currentURL}/user-reports.html`;
//     iframe.addEventListener('load', () => {
//       const doc = iframe.contentDocument;
//       const sidebar = doc.querySelector('.sidebar');
//       const wrapper = doc.querySelector('.wrapper');
//       const header = doc.querySelector('.header');
//       const pagetitle = doc.querySelector('#page-title');
//       const wrapperP = doc.querySelector('.wrapper p');
//       const customPageContent = doc.querySelector('.custom-page-content');
//       setTimeout(() => {
//         const warningBanner = doc.querySelector('#warning-banner');
//         if (warningBanner) {
//           warningBanner.style.display = 'none';
//         }
//       }, 1000);
//       sidebar.style.display = 'none';
//       wrapper.style.padding = 0;
//       header.style.display = 'none';
//       pagetitle.style.display = 'none';
//       wrapperP.style.display = 'none';
//       customPageContent.style.margin = 0;
//       customPageContent.style.overflow = 'auto';
//       customPageContent.style.height = '100vh';
//       iframe.style.display = 'block';
//       load.style.display = 'none';
//     });
//   } else if (e === 'activePolicies') {
//     document.querySelectorAll('.card_item').forEach((card) => {
//       card.classList.remove('card_item_active');
//     });
//     const currentCard = document.querySelector('.activePoliciesCard');
//     currentCard.classList.add('card_item_active');
//     iframe.src = `${currentURL}/policy-admin.html`;
//     iframe.addEventListener('load', () => {
//       const doc = iframe.contentDocument;
//       const sidebar = doc.querySelector('.sidebar');
//       const wrapper = doc.querySelector('.wrapper');
//       const header = doc.querySelector('.header');
//       const pagetitle = doc.querySelector('.title-container');
//       const title = doc.querySelector('.title');
//       const divider = doc.querySelector('.divider');
//       const documentsContainer = doc.querySelector('.documents-container');
//       setTimeout(() => {
//         const warningBanner = doc.querySelector('#warning-banner');
//         if (warningBanner) {
//           warningBanner.style.display = 'none';
//         }
//       }, 1000);
//       sidebar.style.display = 'none';
//       wrapper.style.padding = 0;
//       header.style.display = 'none';
//       title.style.display = 'none';
//       divider.style.display = 'none';
//       documentsContainer.style.padding = 0;
//       iframe.style.display = 'block';
//       load.style.display = 'none';
//       setTimeout(() => {
//         const editBtnCol = doc.querySelectorAll('.editBtnCol');
//         editBtnCol.forEach((e) => {
//           e.style.display = 'none';
//         });
//       }, 1000);
//     });
//   } else if (e === 'assuranceTasks') {
//     document.querySelectorAll('.card_item').forEach((card) => {
//       card.classList.remove('card_item_active');
//     });
//     const currentCard = document.querySelector('.assuranceTasksCard');
//     currentCard.classList.add('card_item_active');
//     iframe.src = `${currentURL}/assurance-actions.html`;
//     iframe.addEventListener('load', () => {
//       const doc = iframe.contentDocument;
//       const sidebar = doc.querySelector('.sidebar');
//       const wrapper = doc.querySelector('.wrapper');
//       const header = doc.querySelector('.header');
//       const div1 = doc.querySelector('.main-container >h2:nth-child(1)');
//       const div2 = doc.querySelector('.main-container >div:nth-child(2)');
//       const main_container = doc.querySelector('.main-container');
//       const pageBreak = doc.querySelector('#pageBreakBar');
//       setTimeout(() => {
//         const warningBanner = doc.querySelector('#warning-banner');
//         if (warningBanner) {
//           warningBanner.style.display = 'none';
//         }
//       }, 1000);
//      try {
//        pageBreak.style.display = 'none';
//        sidebar.style.display = 'none';
//        wrapper.style.padding = 0;
//        header.style.display = 'none';
//        div2.style.display = 'none';
//        div1.style.display = 'none';
//        main_container.style.padding = 0;
//        main_container.style.margin = 0;
//        iframe.style.display = 'block';
//        load.style.display = 'none';
//        const newIframeStyle = doc.createElement('style');
//        newIframeStyle.textContent = `
//           .hideOnDashboard {
//             display: none;
//           }
//           .otherCellsHeader {
//             width: 20%;
//           }
//           .otherCells {
//             width: 20%;
//           }
//           `;
//        doc.head.appendChild(newIframeStyle);
//      } catch (error) {
//       console.log(error)
//      }
//     });
//   } else if (e === 'assets') {
//     document.querySelectorAll('.card_item').forEach((card) => {
//       card.classList.remove('card_item_active');
//     });
//     const currentCard = document.querySelector('.assetsCard');
//     currentCard.classList.add('card_item_active');
//     iframe.src = `${currentURL}/assetregister.html`;
//     iframe.addEventListener('load', () => {
//       const doc = iframe.contentDocument;
//       const sidebar = doc.querySelector('.sidebar');
//       const wrapper = doc.querySelector('.wrapper');
//       const header = doc.querySelector('.header');
//       const titleContainer = doc.querySelector('.title-container');
//       const documentsContainer = doc.querySelector('.documents-container');
//       setTimeout(() => {
//         const warningBanner = doc.querySelector('#warning-banner');
//         if (warningBanner) {
//           warningBanner.style.display = 'none';
//         }
//       }, 1000);
//       iframe.style.display = 'block';
//       sidebar.style.display = 'none';
//       wrapper.style.padding = 0;
//       header.style.display = 'none';
//       titleContainer.style.display = 'none';
//       documentsContainer.style.padding = 0;
//       load.style.display = 'none';
//       setTimeout(() => {
//         const editBtnCol = doc.querySelectorAll('.assetEditBtn');
//         const historyBtnCol = doc.querySelectorAll('.assetHistoryBtn');
//         editBtnCol.forEach((e) => {
//           e.style.display = 'none';
//         });
//         historyBtnCol.forEach((e) => {
//           e.style.display = 'none';
//         });
//       }, 1000);
//     });
//   } else if (e === 'risks') {
//     document.querySelectorAll('.card_item').forEach((card) => {
//       card.classList.remove('card_item_active');
//     });
//     const currentCard = document.querySelector('.risksCard');
//     currentCard.classList.add('card_item_active');
//     iframe.src = `${currentURL}/riskregister.html`;
//     iframe.addEventListener('load', () => {
//       const doc = iframe.contentDocument;
//       const sidebar = doc.querySelector('.sidebar');
//       const wrapper = doc.querySelector('.wrapper');
//       const header = doc.querySelector('.header');
//       const titleContainer = doc.querySelector('.title-container');
//       const documentsContainer = doc.querySelector('.documents-container');
//       setTimeout(() => {
//         const warningBanner = doc.querySelector('#warning-banner');
//         if (warningBanner) {
//           warningBanner.style.display = 'none';
//         }
//       }, 1000);
//       iframe.style.display = 'block';
//       sidebar.style.display = 'none';
//       wrapper.style.padding = 0;
//       header.style.display = 'none';
//       titleContainer.style.display = 'none';
//       documentsContainer.style.padding = 0;
//       load.style.display = 'none';
//       setTimeout(() => {
//         const editBtnCol = doc.querySelectorAll('.riskEditBtn');
//         editBtnCol.forEach((e) => {
//           e.style.display = 'none';
//         });
//       }, 1000);
//     });
//   }

//   if (e === 'overdueTasks') {
//     document.querySelectorAll('.card_item').forEach((card) => {
//       card.classList.remove('card_item_active');
//     });
//     const currentCard = document.querySelector('.overdueTasksCard');
//     currentCard.classList.add('card_item_active');
//     iframe.src = `${currentURL}/overduetasks.html`;
//     iframe.addEventListener('load', () => {
//       const doc = iframe.contentDocument;
//       const sidebar = doc.querySelector('.sidebar');
//       const wrapper = doc.querySelector('.wrapper');
//       const header = doc.querySelector('.header');
//       const div2 = doc.querySelector('.main-container >div:nth-child(2)');
//       const main_container = doc.querySelector('.main-container');
//       const pageBreak = doc.querySelector('#pageBreakBar');
//       setTimeout(() => {
//         const warningBanner = doc.querySelector('#warning-banner');
//         if (warningBanner) {
//           warningBanner.style.display = 'none';
//         }
//       }, 1000);
//       pageBreak.style.display = 'none';
//       sidebar.style.display = 'none';
//       wrapper.style.padding = 0;
//       header.style.display = 'none';
//       div2.style.display = 'none';
//       main_container.style.padding = 0;
//       main_container.style.margin = 0;
//       iframe.style.display = 'block';
//       load.style.display = 'none';
//       const newIframeStyle = doc.createElement('style');
//       newIframeStyle.textContent = `
//           .hideOnDashboard {
//             display: none;
//           }
//           .otherCellsHeader {
//             width: 20%;
//           }
//           .otherCells {
//             width: 20%;
//           }
//           `;
//       doc.head.appendChild(newIframeStyle);
//     });
//   }
// }

window.createPopout = function () {
  const popout1 = `<div class="modal first-modal" tabindex="-1" id="first-modal">
                  <div class="modal-dialog">
                    <div class="modal-content first-modal-content">
                      <div class="modal-header first-modal-header">
                        <h5 class="modal-title first-modal-title">Welcome to <b>de.iterate</b></h5>
                      </div>
                      <div class="modal-body first-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Let's have a quick look around</p>
                      </div>
                      <div class="modal-footer first-modal-footer">
                        <button type="button" class="btn btn-outline-warning" onclick = "secondStep()">Begin</button>
                        <button type="button" class="btn btn-outline-warning" data-coreui-dismiss="modal" onclick = "closeButton()">No Thanks</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-back" id="modal-back">
                </div>
                `;
  document.body.insertAdjacentHTML('beforeend', popout1);
};

window.secondStep = function () {
  const userRef = db.collection('users').doc(uid);
  userRef.get().then((doc) => {
    // console.log('userRef', doc.data());
    const data = doc.data();
    const datum = {
      ...data,
      welcomeScreenComplete: false,
    };
    db.collection('users').doc(uid).update(datum);
  });
  const firstModal = document.getElementById('first-modal');
  firstModal.style.display = 'none';
  const popout2 = `<div class="modal second-modal" tabindex="-1" id="second-modal">
                  <div class="modal-dialog">
                    <div class="modal-content second-modal-content">
                      <div class="modal-header second-modal-header">
                        <h5 class="modal-title second-modal-title">You're on the <b>Dashboard</b></h5>
                      </div>
                      <div class="modal-body second-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Use the menu on the left to navigate through your Compliance Portal</p>
                      </div>
                      <div class="modal-footer second-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "thirdStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 177px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">1 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout2);
  const secondModal = document.getElementById('second-modal');
  secondModal.style.display = 'block';
};

function thirdStep() {
  const secondModal = document.getElementById('second-modal');
  secondModal.style.display = 'none';
  const popout3 = `<div class="modal third-modal" tabindex="-1" id="third-modal">
                  <div class="modal-dialog">
                    <div class="modal-content third-modal-content">
                      <div class="modal-body third-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">If you need <b>Help</b> at any time, the Help menu is under your company logo</p>
                      </div>
                      <div class="modal-footer third-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "forthStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 140px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">2 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout3);
  const thirdModal = document.getElementById('third-modal');
  thirdModal.style.display = 'block';
}

window.forthStep = function () {
  const thirdModal = document.getElementById('third-modal');
  thirdModal.style.display = 'none';
  const popout3 = `<div class="modal forth-modal" tabindex="-1" id="forth-modal">
                  <div class="modal-dialog">
                    <div class="modal-content forth-modal-content">
                      <div class="modal-header forth-modal-header">
                        <h5 class="modal-title forth-modal-title">Your Information Security policies are already ready to go!</h5>
                      </div>
                      <div class="modal-body forth-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Head over to <b>Policy Admin</b> to start assigning policies out to users. </p>
                      </div>
                      <div class="modal-footer forth-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "fifthStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 187px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">3 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout3);
  const forthModal = document.getElementById('forth-modal');
  forthModal.style.display = 'block';
};

function fifthStep() {
  const forthModal = document.getElementById('forth-modal');
  forthModal.style.display = 'none';
  const popout4 = `<div class="modal fifth-modal" tabindex="-1" id="fifth-modal">
                  <div class="modal-dialog">
                    <div class="modal-content fifth-modal-content">
                      <div class="modal-header fifth-modal-header">
                        <h5 class="modal-title fifth-modal-title">All your important documentation is stored in <b>Key Documents.</b></h5>
                      </div>
                      <div class="modal-body fifth-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Future onboarding tasks will take you through customising these.</p>
                      </div>
                      <div class="modal-footer fifth-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "sixthStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 215px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">4 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout4);
  const fifthModal = document.getElementById('fifth-modal');
  fifthModal.style.display = 'block';
}

window.sixthStep = function () {
  const fifthModal = document.getElementById('fifth-modal');
  fifthModal.style.display = 'none';
  const popout5 = `<div class="modal sixth-modal" tabindex="-1" id="sixth-modal">
                  <div class="modal-dialog">
                    <div class="modal-content sixth-modal-content">
                      <div class="modal-body sixth-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">The all importing 'doing' part of your compliance are presented as a series of simple <b>Assurance Tasks</b>. </p>
                      </div>
                      <div class="modal-footer sixth-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "seventhStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 165px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">5 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout5);
  const sixthModal = document.getElementById('sixth-modal');
  sixthModal.style.display = 'block';
};

function seventhStep() {
  const sixthModal = document.getElementById('sixth-modal');
  sixthModal.style.display = 'none';
  const popout6 = `<div class="modal seventh-modal" tabindex="-1" id="seventh-modal">
                  <div class="modal-dialog">
                    <div class="modal-content seventh-modal-content">
                      <div class="modal-body seventh-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Lists are boring, so we have plotted your tasks on this handy <b>Compliance Calendar.</b></p>
                      </div>
                      <div class="modal-footer seventh-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "eighthStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 140px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">6 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout6);
  const seventhModal = document.getElementById('seventh-modal');
  seventhModal.style.display = 'block';
}

window.eighthStep = function () {
  const seventhModal = document.getElementById('seventh-modal');
  seventhModal.style.display = 'none';
  const popout7 = `<div class="modal eighth-modal" tabindex="-1" id="eighth-modal">
                  <div class="modal-dialog">
                    <div class="modal-content eighth-modal-content">
                      <div class="modal-body eighth-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Keep an eye on your users reading progress in <b>User Reports.</b></p>
                      </div>
                      <div class="modal-footer eighth-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "ninthStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 140px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">7 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout7);
  const eighthModal = document.getElementById('eighth-modal');
  eighthModal.style.display = 'block';
};

function ninthStep() {
  const eighthModal = document.getElementById('eighth-modal');
  eighthModal.style.display = 'none';
  const popout8 = `<div class="modal ninth-modal" tabindex="-1" id="ninth-modal">
                  <div class="modal-dialog">
                    <div class="modal-content ninth-modal-content">
                      <div class="modal-body ninth-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">Come back anytime and edit your company specific settings in <b>Company Settings</b>.</p>
                      </div>
                      <div class="modal-footer ninth-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "tenthStep()">Next</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 140px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">8 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout8);
  const ninthModal = document.getElementById('ninth-modal');
  ninthModal.style.display = 'block';
}

window.tenthStep = function () {
  const ninthModal = document.getElementById('ninth-modal');
  ninthModal.style.display = 'none';
  const popout9 = `<div class="modal tenth-modal" tabindex="-1" id="tenth-modal">
                  <div class="modal-dialog">
                    <div class="modal-content tenth-modal-content">
                      <div class="modal-body tenth-modal-body">
                        <p style = "text-align: center; font-size: 1.1rem; color: grey; font-family: arial;">When you're ready to keep going. Use our simple configuration wizards under your company logo to get started</p>
                      </div>
                      <div class="modal-footer tenth-modal-footer">
                        <button type="button" style = "width: 100px;" class="btn btn-outline-warning" onclick = "closeButton()">Finish</button>
                        <div style = "display: flex; width: 315px; padding: 0 10px; justify-content: space-between; position: absolute; top: 160px; align-items: center !important;">
                        <button type="button" style ="font-size: 10px;" class="btn-close" data-coreui-dismiss="modal" onclick = "closeButton()"></button>
                        <p style = "font-size: 16px;">9 of 9</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`;
  document.body.insertAdjacentHTML('beforeend', popout9);
  const tenthModal = document.getElementById('tenth-modal');
  tenthModal.style.display = 'block';
};

function closeButton() {
  const userRef = db.collection('users').doc(uid);
  userRef.get().then((doc) => {
    // console.log('userRef', doc.data());
    const data = doc.data();
    const datum = {
      ...data,
      welcomeScreenComplete: false,
    };
    db.collection('users').doc(uid).update(datum);
  });
  const firstModal = document.getElementById('first-modal');
  const secondModal = document.getElementById('second-modal');
  const thirdModal = document.getElementById('third-modal');
  const forthModal = document.getElementById('forth-modal');
  const fifthModal = document.getElementById('fifth-modal');
  const sixthModal = document.getElementById('sixth-modal');
  const seventhModal = document.getElementById('seventh-modal');
  const eighthModal = document.getElementById('eighth-modal');
  const ninthModal = document.getElementById('ninth-modal');
  const tenthdModal = document.getElementById('tenth-modal');
  const modalBack = document.getElementById('modal-back');
  modalBack.style.display = 'none';
  firstModal.style.display = 'none';
  secondModal.style.display = 'none';
  thirdModal.style.display = 'none';
  forthModal.style.display = 'none';
  fifthModal.style.display = 'none';
  sixthModal.style.display = 'none';
  seventhModal.style.display = 'none';
  eighthModal.style.display = 'none';
  ninthModal.style.display = 'none';
  tenthdModal.style.display = 'none';
}

function getAllIsmsRoles() {
  const ismsRolesdb = db
    .collection('customers')
    .doc(window.companyID)
    .collection('product/policyPackages/ISO27001keyDocuments');
  ismsRolesdb.get().then(async (res) => {
    await res;
    const ismsManual = res.docs[0].data().ismsRoles;
    const allData = res.docs;

    for (const i in allData) {
      if (+i + 1 === allData.length) {
        for (const e in allData[i].data().uploaded) {
          keyDocument.push(allData[i].data().uploaded[e]);
        }
      }
    }

    for (const e in ismsManual) {
      ismsRoles.push(ismsManual[e]);
    }

    const rolesCard = document.querySelector('.roles');
    const keyDocumentsCard = document.querySelector('.keyDocuments');
    rolesCard.innerHTML = ismsRoles.length;
    keyDocumentsCard.innerHTML = keyDocument.length;
  });
}

// Global Event Listeners

// document.getElementById('privacyActAssuranceTaskChartDiv').addEventListener('click', () => {
//   window.location.href = 'assurance-actions.html';
// });

// document.getElementById('e8AssuranceTaskChartDiv').addEventListener('click', () => {
//   window.location.href = 'assurance-actions.html';
// });

// document.getElementById('suppliersDataMap').addEventListener('click', () => {
//   window.location.href = 'supplierRegister.html';
// });
String.prototype.extract = function (prefix, suffix) {
  s = this;
  var i = s.indexOf(prefix);
  if (i >= 0) {
    s = s.substring(i + prefix.length);
  } else {
    return '';
  }
  if (suffix) {
    i = s.indexOf(suffix);
    if (i >= 0) {
      s = s.substring(0, i);
    } else {
      return '';
    }
  }
  return s;
};
// # sourceMappingURL=index-html.js.map
